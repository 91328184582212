import React from "react";
import { Dialog, DialogContent, DialogTitle, Grid, Link } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const ApprovedDialog = () => {
	return (
		<Dialog open={true}>
			<DialogTitle>
				<div style={{ display: "inline-flex" }}>
					<CheckCircleOutlineIcon style={{ color: "green", fontSize: 32 }} /> &nbsp;PSP has been approved
				</div>
			</DialogTitle>
			<DialogContent>
				<Grid container>
					<Grid
						item
						xs={12}
						xl={12}>
						This PSP has already been approved, no action is needed. If you need to view it, please download the PSP
						Pdf.
					</Grid>
					<Grid
						item
						xs={12}>
						&nbsp;
					</Grid>
					<Grid
						item
						xs={6}
						xl={6}>
						{" "}
					</Grid>
					<Grid
						item
						xs={6}
						xl={6}>
						Return to <Link href="/mypsp">My PSP</Link> OR <Link href="/">Home</Link>
					</Grid>
				</Grid>{" "}
			</DialogContent>
		</Dialog>
	);
};

export default ApprovedDialog;
