const initialState = {
	activityLevels: [],
	isProcessing: false,
	isError: false,
};

const ActivityLevelReducer = (state = initialState, activityLevelAction) => {
	switch (activityLevelAction.type) {
		case "FETCH_ACTIVITYLEVELS": {
			return {
				activityLevels: [],
				isProcessing: false,
				isError: false,
			};
		}
		case "RECEIVED_ACTIVITYLEVELS": {
			return {
				activityLevels: activityLevelAction.payload,
				isProcessing: false,
				isError: false,
			};
		}
		case "FETCH_ACTIVITYLEVELS_ERROR": {
			return {
				activityLevels: [],
				isProcessing: false,
				isError: true,
			};
		}
		default: {
			return state; /* {
                
                activityLevels:state.activityLevels,
                isProcessing:false,
                isError:false
            } */
		}
	}
};

export default ActivityLevelReducer;
