import React from "react";
import { Badge } from "@material-ui/core";
import { AttachFile } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";


const StyledBadge = withStyles((theme) => ({
	badge: {
		right: -10,
		top: 13,
		border: `2px solid ${theme.palette.background.paper}`,
		padding: "0 4px",
	},
}))(Badge);

export default function AttachmentButton({ files }) {

	return (files && files.length) > 0 ? (
		<StyledBadge
			badgeContent={files.length}
			color="secondary">
			<AttachFile style={{ color: "white" }}></AttachFile>
		</StyledBadge>
	) : (
		<AttachFile style={{ color: "white" }}></AttachFile>
	);
}
