const fetch_ActivityLevels = () => {
	return {
		type: "FETCH_ACTIVITYLEVELS",
	};
};

const received_ActivityLevels = (ActivityLevels) => {
	return {
		type: "RECEIVED_ACTIVITYLEVELS",
		payload: ActivityLevels,
	};
};

const fetch_ActivityLevels_Error = () => {
	return {
		type: "FETCH_ACTIVITYLEVELS_ERROR",
	};
};

export default {
	fetch_ActivityLevels,
	received_ActivityLevels,
	fetch_ActivityLevels_Error,
};
