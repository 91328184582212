import { InputLabel, TextareaAutosize } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { contact } from "../../../models/contact";
import { facility } from "../../../models/facility";
import { site } from "../../../models/site";
import allActions from "../../../redux/actions";
import { clearScopes } from "../../../redux/reducers/ticketReducer";
import { StepFourStyles } from "../../../Styles";
import SiteAccess from "../../Control/SiteAccess.Component";
import VersionCheck from "../../Control/VersionCheck";
import TicketScope from "../../Ticket/ticketScope";
import { RotateSpinner } from "react-spinners-kit";

const emptyContact = { ...contact };

const StepFour = () => {
	const classes = StepFourStyles();
	const dispatch = useDispatch();
	const [objFacility, setFacility] = useState({ ...facility });
	const [objContact, setContact] = useState({ ...contact });
	const [, setState] = useState({});
	const [loaded, setLoaded] = useState(false);
	const isSubmitted = useSelector((state) => state.ProjectReducer.projectInfo.projectStatuses[0].statusId);
	const currentPsp = useSelector((state) => state.ProjectReducer);
	const isReadOnly = useSelector((state) => state.ProjectReducer.isReadOnly);
	const objSite = currentPsp.projectInfo.site === undefined ? site : currentPsp.projectInfo.site;

	useEffect(() => {
		dispatch(clearScopes());
		setLoaded(true);
	}, [dispatch, isSubmitted]);

	const handleCheckBox = (event) => {
		if (event.target.checked) {
			objSite.no911 = true;
		} else {
			objSite.no911 = false;
		}
		currentPsp.projectInfo.site = objSite;
		dispatch(allActions.ProjectActions.saved_Project(currentPsp.projectInfo));
		setState({});
	};

	const handleEmergencyContactInfo = (event) => {
		objSite.emergencyContactInfo = event.target.value;
		currentPsp.projectInfo.site = objSite;
		dispatch(allActions.ProjectActions.saved_Project(currentPsp.projectInfo));
		setState({});
	};

	//Adding Facility to the table
	const handleAddFacility = () => {
		objFacility.facitilyTypeId = 1;
		currentPsp.projectInfo.projectFacilities.push({ facility: objFacility });
		dispatch(allActions.ProjectActions.saved_Project(currentPsp.projectInfo));
		setFacility({ ...facility });
	};

	//Handle deleting facility
	const handleDeleteFacility = (facilityName) => () => {
		currentPsp.projectInfo.projectFacilities = currentPsp.projectInfo.projectFacilities.filter(
			(objValue) => objValue.facility.facilityName !== facilityName,
		);
		dispatch(allActions.ProjectActions.saved_Project(currentPsp.projectInfo));
		setFacility({ ...facility });
	};

	//Replacing value as user type
	const handleFacilityOnChange = (event) => {
		objFacility[event.target.id] = event.target.value;
		setFacility({ ...objFacility });
	};

	//Adding contact to the table
	const handleOtherContact = () => {
		objContact.contactTypeId = 2;
		currentPsp.projectInfo.projectContacts.push({ contact: objContact });
		dispatch(allActions.ProjectActions.saved_Project(currentPsp.projectInfo));
		setContact({ ...emptyContact });
	};

	//Handle deleting other contact
	const handleDeleteOtherContact = (contactAgency) => () => {
		currentPsp.projectInfo.projectContacts = currentPsp.projectInfo.projectContacts.filter(
			(objContact) => objContact.contact.contactAgency !== contactAgency,
		);
		dispatch(allActions.ProjectActions.saved_Project(currentPsp.projectInfo));
		setContact({ ...contact });
	};

	//Replacing value as user type
	const handleContactOnChange = (event) => {
		objContact[event.target.id] = event.target.value;
		console.log("I am in on change");
		setContact({ ...objContact });
	};

	return !loaded ? (
		<div style={{ padding: "2rem" }}>
			<RotateSpinner
				size={45}
				color="#686769"
			/>
		</div>
	) : (
		<>
			<VersionCheck />
			<div className={classes.root}>
				<Grid
					container
					spacing={3}>
					<Grid
						item
						xs={12}>
						<Card>
							<CardHeader
								className={classes.cardHeader}
								title="Step 4: Project Contacts"
							/>
							<br />
							<CardContent>
								<TicketScope scope="step4-projectContacts">
									<Typography variant="h6">Emergency Communication</Typography>
									<Grid
										container
										spacing={4}>
										<Grid
											item
											xs={6}>
											<Typography variant="body1">Dial 911 for all emergencies</Typography>
											<br />
											<FormControlLabel
												control={
													<Checkbox
														onClick={handleCheckBox}
														name="no911"
														color="secondary"
														checked={objSite.no911}
														disabled={isReadOnly}
													/>
												}
												label={<Typography variant="body2">911 is not available at this site</Typography>}
											/>
										</Grid>
										<Grid
											item
											xs={6}>
											<InputLabel>Other Alternative Emergency Numbers</InputLabel>
											<br />
											<TextareaAutosize
												className={classes.fullWidthTextArea}
												maxRows={4}
												minRows={4}
												variant="outlined"
												onChange={handleEmergencyContactInfo}
												id="emergencyContactInfo"
												value={!objSite.emergencyContactInfo ? "" : objSite.emergencyContactInfo}
												disabled={isReadOnly}
											/>
										</Grid>
									</Grid>

									<hr />
									<SiteAccess />
									<br />
									<hr />

									{/* Map Component */}

									{/* Local Hospital(s) Info */}
									<Typography variant="h6">Local Hospital(s)</Typography>
									<br />
									<Grid
										container
										spacing={4}>
										<Grid
											item
											xs={3}>
											<InputLabel>Name of Local Hospital</InputLabel>
											<br />
											<TextareaAutosize
												className={classes.fullWidthTextArea}
												maxRows={1}
												minRows={1}
												variant="outlined"
												id="facilityName"
												onChange={handleFacilityOnChange}
												value={objFacility.facilityName}
												disabled={isReadOnly}
											/>
											<br />
											<InputLabel>Address</InputLabel>
											<br />
											<TextareaAutosize
												className={classes.fullWidthTextArea}
												maxRows={1}
												minRows={1}
												variant="outlined"
												id="facilityAddress"
												onChange={handleFacilityOnChange}
												value={objFacility.facilityAddress}
												disabled={isReadOnly}
											/>
											<br />
											<InputLabel>Hospital Phone Number</InputLabel>
											<br />
											<TextareaAutosize
												className={classes.fullWidthTextArea}
												maxRows={1}
												minRows={1}
												variant="outlined"
												id="facilityContact"
												onChange={handleFacilityOnChange}
												value={objFacility.facilityContact}
												disabled={isReadOnly}
											/>
											<br />
											<InputLabel>Route to Hospital</InputLabel>
											<br />
											<TextareaAutosize
												className={classes.fullWidthTextArea}
												maxRows={4}
												minRows={4}
												variant="outlined"
												id="facilityRouteInfo"
												onChange={handleFacilityOnChange}
												value={objFacility.facilityRouteInfo}
												disabled={isReadOnly}
											/>
											<br />
											<Button
												variant="contained"
												color="secondary"
												onClick={handleAddFacility}
												className={classes.buttonFull}
												disabled={isReadOnly}>
												Add Facility To The List
											</Button>
										</Grid>
										<Grid
											item
											xs={9}>
											<Table>
												<TableHead>
													<TableRow>
														<TableCell>Hospital</TableCell>
														<TableCell>Address</TableCell>
														<TableCell>Phone Number</TableCell>
														<TableCell>Route To Hospital</TableCell>
														<TableCell>Delete</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{currentPsp.projectInfo.projectFacilities !== undefined
														? currentPsp.projectInfo.projectFacilities.map((objValue, index) => (
																<TableRow key={index}>
																	<TableCell>{objValue.facility.facilityName}</TableCell>
																	<TableCell>{objValue.facility.facilityAddress}</TableCell>
																	<TableCell>{objValue.facility.facilityContact}</TableCell>
																	<TableCell>{objValue.facility.facilityRouteInfo}</TableCell>
																	<TableCell>
																		<Button
																			variant="contained"
																			color="secondary"
																			className={classes.buttonFull}
																			onClick={handleDeleteFacility(objValue.facility.facilityName)}>
																			Delete
																		</Button>
																	</TableCell>
																</TableRow>
															))
														: console.log("Empty")}
												</TableBody>
											</Table>
										</Grid>
									</Grid>
									<br />
									<hr />
									{/* Other Contact(s) Info */}
									<Typography variant="h6">Other Contacts</Typography>
									<br />
									<Grid
										container
										spacing={4}>
										<Grid
											item
											xs={3}>
											<InputLabel>Agency Name</InputLabel>
											<br />
											<TextareaAutosize
												className={classes.fullWidthTextArea}
												maxRows={1}
												minRows={1}
												variant="outlined"
												id="contactAgency"
												onChange={handleContactOnChange}
												value={objContact.contactAgency}
												disabled={isReadOnly}
											/>
											<br />
											<InputLabel>Contact Name</InputLabel>
											<br />
											<TextareaAutosize
												className={classes.fullWidthTextArea}
												maxRows={1}
												minRows={1}
												variant="outlined"
												id="contactName"
												onChange={handleContactOnChange}
												value={objContact.contactName}
												disabled={isReadOnly}
											/>
											<br />
											<InputLabel>Location</InputLabel>
											<br />
											<TextareaAutosize
												className={classes.fullWidthTextArea}
												maxRows={1}
												minRows={1}
												variant="outlined"
												id="contactLocation"
												onChange={handleContactOnChange}
												value={objContact.contactLocation}
												disabled={isReadOnly}
											/>
											<br />
											<InputLabel>Phone Number</InputLabel>
											<br />
											<TextareaAutosize
												className={classes.fullWidthTextArea}
												maxRows={1}
												minRows={1}
												variant="outlined"
												id="contactCell"
												onChange={handleContactOnChange}
												value={objContact.contactCell}
												disabled={isReadOnly}
											/>
											<br />
											<InputLabel>Comments</InputLabel>
											<br />
											<TextareaAutosize
												className={classes.fullWidthTextArea}
												maxRows={4}
												minRows={4}
												variant="outlined"
												id="contactComments"
												onChange={handleContactOnChange}
												value={objContact.contactComments}
												disabled={isReadOnly}
											/>
											<br />
											<Button
												variant="contained"
												color="secondary"
												onClick={handleOtherContact}
												className={classes.buttonFull}
												disabled={isReadOnly}>
												Add Contact To The List
											</Button>
										</Grid>
										<Grid
											item
											xs={9}>
											<Table>
												<TableHead>
													<TableRow>
														<TableCell>Agency</TableCell>
														<TableCell>Contact Name</TableCell>
														<TableCell>Location</TableCell>
														<TableCell>Phone Number</TableCell>
														<TableCell>Comments</TableCell>
														<TableCell>Delete</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{currentPsp.projectInfo.projectContacts.filter(
														(objValue) => objValue.contact.contactTypeId === 2,
													) !== undefined ? (
														currentPsp.projectInfo.projectContacts
															.filter((objValue) => objValue.contact.contactTypeId === 2)
															.map((objValue, index) => (
																<TableRow key={index}>
																	<TableCell>{objValue.contact.contactAgency}</TableCell>
																	<TableCell>{objValue.contact.contactName}</TableCell>
																	<TableCell>{objValue.contact.contactLocation}</TableCell>
																	<TableCell>{objValue.contact.contactCell}</TableCell>
																	<TableCell>{objValue.contact.contactComments}</TableCell>
																	<TableCell>
																		<Button
																			variant="contained"
																			color="secondary"
																			className={classes.buttonFull}
																			onClick={handleDeleteOtherContact(objValue.contact.contactAgency)}>
																			Delete
																		</Button>
																	</TableCell>
																</TableRow>
															))
													) : (
														<></>
													)}
												</TableBody>
											</Table>
										</Grid>
									</Grid>
								</TicketScope>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default StepFour;
