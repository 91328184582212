/*
    Author : Peter Ross
    Description : Simple header for PSP
*/
import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Consumer } from "react-keycloak-context";
import { useSelector } from "react-redux";
import bootstrapFromAPI from "../../bootstrapData";
import { IsDevCopy } from "../../config";
import allActions from "../../redux/actions";
import { dataStore } from "../../redux/reduxStore";
import { isAdmin } from "../../utilities/utilityFunctions";
import Logout from "../Authentication/Logout-button";
import PSPMenu from "./MenuComponent";
import { Reviewers } from "../../utilities/utilityFunctions";
import { getFakeUser } from "../Authentication/fakeUsers";
import HasRole from "../Authentication/HasRole";
import { FormControl } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { Select } from "@material-ui/core";

export default function Header({ bubbleEvt }) {
	const authenticated = useSelector((state) => state.AuthenReducer.isSecure);
	const userObj = useSelector((state) => state.AuthenReducer.userObj);
	const [, setAdmin] = useState(false);
	const [userList, setUL] = useState([]);
	const [, setVersions] = useState();

	const radioChange = (evnt, val) => {
		let user = getFakeUser(val.props.value.friendName, val.props.value.userName);
		console.log("Switching to fake user account", user);
		dataStore.dispatch(allActions.AuthenActions.received_Authen(user));
	};

	useEffect(() => {
		if (!userObj) {
			console.log("Unk user");
			dataStore.dispatch(allActions.AuthenActions.received_Authen(undefined)); //to set secure to false
		}
		bootstrapFromAPI.getVersionInfo().then((vers) => {
			setVersions(vers);
		});
		if (userObj.user.mail) isAdmin(userObj.user.mail.toLowerCase()).then((xadmin) => setAdmin(xadmin));
		Reviewers().then((resp) => setUL(resp));
	}, [userObj]);

	return (
		<div>
			<div style={{ padding: 10 }}>
				<table width="100%">
					<tbody>
						<tr>
							<td valign="middle">
								<div style={{ display: "inline-flex" }}>
									<img
										src={require("../../images/Header_Icon_Hardhat_Circle.png")}
										alt="Hard Hat"
									/>
									&nbsp;&nbsp;
									<Typography
										variant="h4"
										style={{
											color: IsDevCopy() ? "#e38d02" : "#666666",
											marginTop: 10,
										}}>
										e-Safety System
									</Typography>
									{IsDevCopy() && <>Development</>}
								</div>
							</td>
							<td align="right">
								<Grid container>
									<Grid
										item
										md={4}>
										<Consumer>
											{(token) => (
												<>
													<Grid
														item
														md={1}>
														{" "}
														<HasRole role="admin-impersonate">
															{" "}
															<FormControl>
																<InputLabel id="demo-simple-select-label"> {userObj.user.displayName}</InputLabel>
																<Select
																	id="demo-simple-select"
																	onChange={radioChange}
																	value=""
																	style={{ width: 200, color: "red" }}>
																	{userList.map((user) => (
																		<MenuItem
																			value={user}
																			key={user._id}>
																			{user.friendName}
																		</MenuItem>
																	))}
																</Select>
															</FormControl>
														</HasRole>
													</Grid>
												</>
											)}
										</Consumer>
									</Grid>
									<Grid
										item
										md={4}>
										<Logout />
									</Grid>

									<Grid
										item
										md={4}>
										<Typography variant="h6">{userObj.user.displayName}</Typography>
									</Grid>
								</Grid>
							</td>
						</tr>
						<tr>
							<td colSpan={2}></td>
						</tr>
					</tbody>
				</table>
			</div>
			{authenticated && <PSPMenu bubbleEvt={bubbleEvt} />}
		</div>
	);
}
