import React, { useEffect, useState } from "react";

import { Button, Dialog, Grid, IconButton, Paper } from "@material-ui/core";
import { Document, Page, pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RotateSpinner } from "react-spinners-kit";
import { API_URI_BASE } from "../../../config";
import CreateIcon from "@material-ui/icons/Create"; //replaces faPen
import HighlightOffIcon from "@material-ui/icons/HighlightOff"; //replaces faTimesCircle

import bootstrapFromAPI from "../../../bootstrapData";
import { Create, SkipNext, SkipPrevious } from "@material-ui/icons";

export default function Signatures() {
	const sig = useSelector((state) => state.TicketReducer.activeSig);
	const [pdfPage, setPdfPage] = React.useState(1);
	const [pdfPages, setPdfPages] = React.useState(1);
	const [pdfScale] = React.useState(2.0);
	const params = useParams();
	const reportId = params.reportId;
	const [doc] = React.useState(API_URI_BASE() + "/Report/" + reportId);
	const [diagOpen, setDiagOpen] = React.useState(false);
	const user = useSelector((state) => state.AuthenReducer.userObj.user.displayName);
	const userEmail = useSelector((state) => state.AuthenReducer.userObj.user.mail);
	const [signed, setSign] = useState(sig !== "" && sig !== null ? true : false);
	console.log(sig);
	const [pdfHeight] = React.useState(parseInt(window.innerHeight - 400));

	useEffect(() => {
		const sig = async () => {
			var sigx = await bootstrapFromAPI.getSignature(userEmail, reportId).then((result) => {
				setSign(sigx !== "" && sigx !== null && sigx !== undefined);
				console.log(sigx !== "" && sigx !== null && sigx !== undefined);
				return sigx;
			});
		};
		sig();
	}, [userEmail, reportId, setSign]);
	const signature = () => {
		console.log("Asking for signature image", isSigned(), signed);
		return isSigned() ? (
			<img
				alt={user + "'s Signature"}
				src={API_URI_BASE() + "/Signatures/sigimage/" + user}
				style={{ display: "flex-end", width: 350 }}
			/>
		) : (
			<div> </div>
		);
	};
	const isSigned = () => {
		if (sig === undefined) return false;
		return sig.signatureId !== undefined;
	};
	async function setSigned(val) {
		setSign(val);
		//Add signature to project and submit.
		var sig = {
			projectId: reportId,
			signatureDate: new Date().toLocaleDateString(),
			version: 0,
			signatureUser: userEmail,
			signatureUserName: user,
		};
		await bootstrapFromAPI.saveSignature(sig);
		setSign(sig);
		var sigx = await bootstrapFromAPI.getSignature(userEmail, reportId).then((result) => {
			setSign(sigx !== "" && sigx !== null && sigx !== undefined);
			console.log(sigx !== "" && sigx !== null && sigx !== undefined);
			return sigx;
		});
	}
	const showDialog = () => {
		return (
			<Dialog
				open={diagOpen}
				maxWidth={800}
				style={{ backgroundColor: "transparent" }}>
				<Paper
					elevation={0}
					style={{
						width: 800,
						height: 650,
						borderRadius: 20,
						backgroundColor: "#f1f1f1",
					}}>
					<div
						width={"100%"}
						style={{ fontSize: 24, fontWeight: "bold", textAlign: "right" }}>
						<HighlightOffIcon onClick={() => setDiagOpen(false)} />
					</div>
					<div style={{ alignSelf: "center", textAlign: "center" }}>
						<div
							style={{
								fontSize: 24,
								fontWeight: "bold",
								backgroundColor: "#f6f2f2",
							}}>
							Sign Project Safety Plan
						</div>

						<div style={{ textAlign: "left", padding: 20, lineHeight: 1.5 }}>
							<br></br>I acknowledge that I have received a copy of the Project Safety Plan for ESA employees.I agree to
							read it thoroughly, including the statements in foreword describing the purpose and effect of the Safety
							Plan. I agree that if there is any policy or provision in the Safety Plan that I do not understand I will
							seek clarification from my Supervisor or the appropriate member of staff.<br></br>
							<br></br>
							This Project Safety Plan states the employer's policies and practices in effect on the date of
							publication. To the extent that the Project Safety Plan sets out policies, that are to be complied with by
							all employees, failure to do so is a violation and may result in disciplinary action up to and including
							termination.
							<br></br>
							<br></br>I understand that the employer may revise, modify, delete or add to any and all policies,
							procedures and work rules in this Safety Plan or in any other document.I also understand that nothing
							contained in the Safety Plan may be construed as creating a promise of future benefits or a binding
							contract with this Company for benefits or for any other purpose.
						</div>
					</div>
					<div style={{ textAlign: "left", padding: 10 }}>
						<Grid container>
							<Grid
								item
								xs={12}>
								<div style={{ height: 60, display: "block", border: "2px" }}>
									{signature()}{" "}
									{!isSigned() && (
										<IconButton
											hidden={signed}
											onClick={() => setSigned(true)}>
											{" "}
											<CreateIcon />
											Click To Sign
										</IconButton>
									)}
								</div>
							</Grid>

							<Grid
								item
								xs={6}>
								<hr />
							</Grid>
							<Grid
								item
								xs={6}>
								{isSigned() && <div></div>}
							</Grid>
							<Grid>
								<div>{user}</div>
								<div>{isSigned() && new Date().toLocaleDateString()}</div>
							</Grid>
						</Grid>
					</div>
				</Paper>
			</Dialog>
		);
	};
	const pdfToolBar = () => {
		return (
			<Grid container>
				<Grid
					item
					xs={9}></Grid>
				<Grid
					item
					xs={1}>
					<Button
						variant="contained"
						color="primary"
						startIcon={<SkipPrevious />}
						onClick={() => setPdfPage(pdfPage - 1)}
						disabled={pdfPage === 1}>
						Prev
					</Button>
				</Grid>
				<Grid
					item
					xs={1}>
					<Button
						variant="contained"
						color="primary"
						startIcon={<SkipNext />}
						onClick={() => setPdfPage(pdfPage + 1)}
						disabled={pdfPages === pdfPage}>
						Next
					</Button>{" "}
				</Grid>
				<Grid
					item
					xs={1}>
					{!isSigned() ? (
						<Button
							color="primary"
							variant="contained"
							startIcon={<Create />}
							disabled={!(pdfPages === pdfPage)}
							onClick={() => setDiagOpen(true)}>
							Sign
						</Button>
					) : (
						<div>You have already signed this PSP</div>
					)}
				</Grid>
			</Grid>
		);
	};

	pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	function setPages(pages) {
		setPdfPages(pages.numPages);
		setPdfPage(1);
	}
	return (
		<div style={{ width: "100%", textAlign: "center" }}>
			{pdfToolBar()}
			{showDialog()}
			{doc && (
				<Paper
					elevation={5}
					style={{ borderRadius: 10 }}>
					<Grid
						container
						style={{
							width: "100%",
							textAlign: "center",
							backgroundColor: "#f1f1f1",
						}}>
						<Grid
							item
							xs={4}></Grid>
						<Grid
							item
							xs={4}>
							{" "}
							Page {pdfPage} of {pdfPages}&nbsp;
						</Grid>
						<Grid
							item
							xs={4}></Grid>

						<Grid
							item
							xs={2}></Grid>

						<Grid
							item
							xl={8}>
							<Paper elevation={5}>
								<Document
									elevation="4"
									renderMode="canvas"
									loading={
										<RotateSpinner
											size={100}
											color="#686769"
										/>
									}
									onLoadSuccess={setPages}
									file={doc}
									onLoadError={(error) => {
										alert("Error while loading document! " + error.message);
										console.log(error.message);
									}}
									onSourceError={(error) => {
										alert("Error while loading document! " + error.message);
										console.log(error.message);
									}}>
									<Page
										style={{
											page: { backgroundColor: "tomato" },
											section: {
												color: "white",
												textAlign: "center",
												margin: 30,
											},
										}}
										border={4}
										borderRadius={5}
										height={pdfHeight}
										scale={pdfScale}
										pageNumber={pdfPage}
										loading={"Generating page preview...."}
									/>
								</Document>
							</Paper>
						</Grid>

						<Grid
							item
							xs={2}></Grid>
						<Grid
							item
							xs={4}></Grid>
						<Grid
							item
							xs={4}>
							{" "}
							Page {pdfPage} of {pdfPages}&nbsp;
						</Grid>
						<Grid
							item
							xs={4}></Grid>
					</Grid>
				</Paper>
			)}
		</div>
	);
}
