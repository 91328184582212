import React, { useState, useEffect } from "react";
import bootstrapFromAPI from "../../../bootstrapData";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { StepFiveStyles } from "../../../Styles";
import VersionCheck from "../../Control/VersionCheck";
import { site } from "../../../models/site";
import { Button } from "@material-ui/core";
import allActions from "../../../redux/actions";
import { setActiveReview } from "../../../redux/reducers/ticketReducer";

const StepSix = () => {
	const classes = StepFiveStyles();
	const currentPsp = useSelector((state) => state.ProjectReducer);
	const review = useSelector((state) => state.TicketReducer);
	const userObj = useSelector((state) => state.AuthenReducer.userObj);
	const [errorMessages, setErrorMessages] = useState([]);
	const [noActivityLevels, setNoActivityLevels] = useState(false);
	const objSite = currentPsp.projectInfo.site === undefined ? site : currentPsp.projectInfo.site;
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		const activityLevels = currentPsp.projectInfo.projectActivityLevels.find((al) => al.activityLevelId === 10);

		if (activityLevels) {
			setNoActivityLevels(true);
		} else {
			let allErrors = [];
			let stepTwoErrors = [];
			let stepFourErrors = [];
			if (
				currentPsp.projectInfo.site.siteNameLocation === undefined ||
				currentPsp.projectInfo.site.siteNameLocation === null ||
				currentPsp.projectInfo.site.siteNameLocation === ""
			) {
				stepTwoErrors.push({
					message: "Site name & location is a required field.",
				});
			}

			if (!currentPsp.projectInfo.site.sow || currentPsp.projectInfo.site.sow === "") {
				stepTwoErrors.push({ message: "Scope of work is a required field." });
			}

			if (!currentPsp.projectInfo.site.siteDescription || currentPsp.projectInfo.site.siteDescription === "") {
				stepTwoErrors.push({ message: "Site description is a required field." });
			}

			if (!currentPsp.projectInfo.site.hazardSum || currentPsp.projectInfo.site.hazardSum === "") {
				stepTwoErrors.push({
					message: "Site hazard review summary is a required field.",
				});
			}
			if (objSite.no911 === true && (objSite.emergencyContactInfo === "" || objSite.emergencyContactInfo === null)) {
				stepFourErrors.push({
					message: "Other alternative emergency numbers is a required field.",
				});
			}

			let step = {};
			if (stepTwoErrors.length > 0) {
				step = {
					step: "Step 2-2: Project Information",
					errors: stepTwoErrors,
				};
				allErrors.push(step);
			}

			if (stepFourErrors.length > 0) {
				step = {
					step: "Step 4: Contacts & Emergency",
					errors: stepFourErrors,
				};
				allErrors.push(step);
			}

			if (allErrors.length > 0) {
				setErrorMessages(allErrors);
			}
		}
	}, [currentPsp, objSite]);

	const handleSubmit = () => {
		dispatch(allActions.ProjectActions.set_Project_Ready_To_Submit(true));
	};

	const handleApproved = () => {
		// approvals don't care if comments are resolved
		// we do care about passing step validations
		// errorMessages must equal 0

		//  NOTE: could refactor this to be handled in ProgressTracker so we dont' have to hook into API  - 3/7 JS
		let updatedReview = JSON.parse(JSON.stringify(review));

		let tmpProject = JSON.parse(JSON.stringify(currentPsp.projectInfo));
		if (tmpProject.projectId !== updatedReview.projectId) {
			///weird bug, let's see if we can find the appropriate review
			console.log("there was a mismatch on the project id, falling back and looking for the correct rreview");
			let username = userObj.user.mail.toLowerCase();
			let userReview = tmpProject.projectReviews.filter(
				(review) => review.reviewer.userName.toLowerCase() === username,
			);
			if (userReview.length > 0) {
				console.log("Found the correct review", userReview[0]);
				updatedReview = JSON.parse(JSON.stringify(userReview[0]));
				dispatch(setActiveReview(updatedReview));
			} else {
				console.log("Could not find any reviews in project for " + username, tmpProject);
			}
		}
		updatedReview = JSON.parse(JSON.stringify(updatedReview));
		updatedReview.approved = true;
		let p = tmpProject.projectReviews.filter((a) => a.reviewId === updatedReview.reviewId);
		if (p.length === 0) {
			alert("Something went wrong. Please send console log to Peter ;)");
			return;
		}
		p[0].approved = true;
		p[0].approvalDate = undefined; // this is set in the api and should not be set in UI

		bootstrapFromAPI.saveProject(tmpProject).then((response) => {
			if (response === "OK") {
				history.push("/mypsp");
			}
		});
	};

	return (
		<>
			<VersionCheck />
			<div className={classes.root}>
				<Grid
					container
					spacing={3}>
					<Grid
						item
						xs={12}>
						<Card>
							<CardHeader
								className={classes.cardHeader}
								title="Review"
							/>
							<CardContent>
								{noActivityLevels ? (
									<Grid
										container
										spacing={4}
										className={classes.card}>
										<Grid
											item
											xs={12}>
											<Typography variant="h6">No Field Activity</Typography>
											<br />
											<Typography variant="body1">
												This project does not require a site visit or field work to complete the work.
											</Typography>
											<br />
											<Typography variant="body1">PSP is ready to be submitted.</Typography>
										</Grid>
										<span></span>
									</Grid>
								) : (
									<Grid
										container
										spacing={4}
										className={classes.card}>
										<Grid
											item
											xs={12}>
											{errorMessages.length > 0 ? (
												<>
													<Typography variant="h6">
														Validation Error(s) need to be address before submission.
													</Typography>
													<br />
													{errorMessages.map((error) => {
														return (
															<div key={error.message}>
																<Typography
																	variant="h6"
																	style={{ fontWeight: "bold" }}>
																	{error.step}
																</Typography>
																<br />
																{error.errors.map((x) => {
																	return (
																		<Typography
																			variant="body1"
																			style={{ color: "red" }}>
																			{x.message}
																		</Typography>
																	);
																})}
																<br />
															</div>
														);
													})}
												</>
											) : (
												<Typography variant="h6">
													PSP is ready to be {currentPsp.projectState === 1 ? "submitted." : "approved."}{" "}
												</Typography>
											)}
										</Grid>
										<span></span>
									</Grid>
								)}
							</CardContent>
							{currentPsp.projectState !== 1 ? (
								<></>
							) : (
								<Button
									disabled={errorMessages.length > 0}
									variant="contained"
									color="primary"
									onClick={handleSubmit}
									style={{ marginBottom: "1rem", marginLeft: "1rem" }}>
									Submit
								</Button>
							)}

							{review?.activeReview?.reviewer?.canApprove && (
								<Button
									disabled={errorMessages.length > 0}
									variant="contained"
									color="primary"
									onClick={handleApproved}
									style={{ marginBottom: "1rem", marginLeft: "1rem" }}>
									Approve
								</Button>
							)}
							{/*
								// THIS IS FOR SME's only. SME's CAN ONLY MARK AS REVIEWED, NOT APPROVE OR APPROVE WITH COMM
								<Button
									disabled={errorMessages.length > 0}
									variant="contained"
									color="primary"
									onClick={handleApproved}
									style={{ marginBottom: "1rem", marginLeft: "1rem" }}>
									Mark as Reviewed
								</Button>
							)} */}
						</Card>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default StepSix;
