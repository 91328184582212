import { Container, List, ListItem } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { HomeStyles } from "../../Styles";
import MyPSP from "../Control/MyPSP.Component";
import { useSelector, useDispatch } from "react-redux";
import allActions from "../../redux/actions";
import FileUpload from "../Control/FileUpload.Component";
import { iconColumnStyles } from "../../utilities/columnHelpers";
import GridIconButton from "../Control/GridIconButton.Component";

const HomeComponent = () => {
	const classes = HomeStyles();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(allActions.ProjectActions.clear_Project());
		dispatch(allActions.DeltekProjectActions.clear_Deltek_Project());
	}, [dispatch]);

	const defaultColumns = [
		{ field: "org", headerName: "Region", width: 150 },
		{ field: "projectNumber", headerName: "Project Number", width: 175 },
		{ field: "projectName", headerName: "Project Name", flex: 0.75 },
		{ field: "lastUpdated", headerName: "Date Updated", flex: 0.4 },
		{
			...iconColumnStyles,
			field: "wbs1",
			headerName: "Attachments",
			flex: 0.25,
			renderCell: (params) => (
				<FileUpload
					wbs1={params.row.projectNumber}
					showAttachments={params.row.showAttachments}
				/>
			),
		},
		{
			...iconColumnStyles,
			field: "report",
			headerName: "Report",
			flex: 0.25,
			renderCell: (params) => (
				<GridIconButton
					params={params}
					type="report"
				/>
			),
		},
		{
			...iconColumnStyles,
			field: "acknowledgement",
			headerName: "Acknowledgement",
			flex: 0.25,
			renderCell: (params) => (
				<GridIconButton
					params={params}
					type="acknowledgement"
				/>
			),
		},
	];

	const objApproved = {};
	var data = [];

	data = useSelector((state) => state.MyPSPReducer.MyPSP);


	objApproved.mypsptitle = "Approved";
	objApproved.columns = defaultColumns;
	objApproved.rows = data.filter((obj) => obj.statusId === 3);

	return (
		<>
			<br />
			<br />
			<Grid
				container
				spacing={3}>
				{/* Top part */}
				<Grid
					item
					xs={3}>
					<Link
						to="/step1"
						className={classes.homeLink}>
						<Paper className={classes.paper}>
							<Card className={classes.newPSP}>
								<Container>
									<List>
										<ListItem>
											<div className={classes.iconText}>
												<img
													src={require("../../images/Home_Icon-Clipboard_Circle.png")}
													alt="New PSP"
												/>
											</div>
										</ListItem>
										<ListItem>
											<Typography style={{ margin: "auto", fontSize: 24, fontWeight: "bolder" }}>
												Create a new PSP
											</Typography>
										</ListItem>
									</List>
								</Container>
								<Container>
									<hr />
									<Typography className={classes.iconSubText}>
										Every ESA project requires a Project Safety Plan (PSP). Click here to get started
									</Typography>
									<br />
								</Container>
							</Card>
						</Paper>
					</Link>
				</Grid>
				<Grid
					item
					xs={3}>
					<Link
						to="/mypsp"
						className={classes.homeLink}>
						<Paper className={classes.paper}>
							<Card className={classes.report}>
								<Container>
									<List>
										<ListItem>
											<div className={classes.iconText}>
												<img
													src={require("../../images/Home_Icon-Drafts_Circle.png")}
													alt="New PSP"
												/>
											</div>
										</ListItem>
										<ListItem>
											<Typography style={{ margin: "auto", fontSize: 24, fontWeight: "bolder" }}>
												My Draft PSPs
											</Typography>
										</ListItem>
									</List>
								</Container>
								<Container>
									<hr />
									<Typography className={classes.iconSubText}>
										Make edits to your draft PSPs and review/respond to comments.
									</Typography>
									<br />
								</Container>
							</Card>
						</Paper>
					</Link>
				</Grid>
				<Grid
					item
					xs={3}>
					<Link
						to="/resources"
						className={classes.homeLink}>
						<Paper className={classes.paper}>
							<Card className={classes.resources}>
								<Container>
									<List>
										<ListItem>
											<div className={classes.iconText}>
												<img
													src={require("../../images/Home_Icon-Resources_Circle.png")}
													alt="New PSP"
												/>
											</div>
										</ListItem>
										<ListItem>
											<Typography style={{ margin: "auto", fontSize: 24, fontWeight: "bolder" }}>Resources</Typography>
										</ListItem>
									</List>
								</Container>
								<Container>
									<hr />
									<Typography className={classes.iconSubText}>
										Check out these resources provided by your project safety team officers.
									</Typography>
									<br />
								</Container>
							</Card>
						</Paper>
					</Link>
				</Grid>
				<Grid
					item
					xs={3}>
					<Link
						to="/training"
						className={classes.homeLink}>
						<Paper className={classes.paper}>
							<Card className={classes.quiz}>
								<Container>
									<List>
										<ListItem>
											<div className={classes.iconText}>
												<img
													src={require("../../images/Home_Icon-Training_Circle.png")}
													alt="Resources"
												/>
											</div>
										</ListItem>
										<ListItem>
											<Typography style={{ margin: "auto", fontSize: 24, fontWeight: "bolder" }}>Training</Typography>
										</ListItem>
									</List>
								</Container>
								<Container>
									<hr />
									<Typography className={classes.iconSubText}>
										Stay up-to-date and learn new vital safety knowledge for your next field visit.
									</Typography>
									<br />
								</Container>
							</Card>
						</Paper>
					</Link>
				</Grid>
			</Grid>
			<br />
			<hr />
			<br />
			<Grid
				container
				spacing={3}>
				<Grid
					item
					xs={12}>
					<MyPSP objPSPList={objApproved} />
				</Grid>
			</Grid>
		</>
	);
};

export default HomeComponent;
