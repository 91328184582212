export const contact = {
	contactName: "",
	contactCell: "",
	contactOtherInfo: "",
	contactSatPhone: "",
	contactOtherNumbers: "",
	contactAgency: "",
	contactLocation: "",
	contactTypeId: 1,
	contactComments: "",
};
