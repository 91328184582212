import React from "react";
import { IconButton } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Consumer as KeycloakConsumer } from "react-keycloak-context";

const Logout = () => {
	const doLogout = (cons) => {
		window.location.href = cons.createLogoutUrl();
	};
	return (
		<KeycloakConsumer>
			{(data) => (
				<IconButton
					color="secondary"
					style={{ margin: 0, padding: 0 }}
					size="medium"
					type="submit"
					variant="contained"
					onClick={() => doLogout(data)}>
					<ExitToAppIcon />
				</IconButton>
			)}
		</KeycloakConsumer>
	);
};

export default Logout;
