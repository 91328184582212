const keycloakConfig = {
	realm: "eSafety",
	clientId: "esafety-ui",

	url: "https://identity.esassoc.net:8443/auth",
	"ssl-required": "external",
	"public-client": true,
	"client-secret": "0c8bbcdc-1854-49ca-aa97-c0059e233557",
	"confidential-port": 0,
	redirect_uri: window.location.href,
	silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
};

export default keycloakConfig;
