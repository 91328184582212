import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import LibraryAddCheckOutLineIcon from "@material-ui/icons/LibraryAddCheck";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import PhoneInTalkOutlinedIcon from "@material-ui/icons/PhoneInTalkOutlined";
import PublishIcon from "@material-ui/icons/Publish";
import { useSelector } from "react-redux";
import bootstrapFromAPI from "./bootstrapData";
import Admin from "./components/Admin";
import AdminQuery from "./components/AdminQuery";
import HasRole from "./components/Authentication/HasRole";
import Header from "./components/Control/HeaderComponent";
import ProgressTracker from "./components/Control/ProgressTrackerComponent";
import { PSPSignatureList } from "./components/Control/PSPSignatureList";
import HomeComponent from "./components/Home/Home.Component";
import PSPList from "./components/PSP/MyPSP/PSP.List.Component";
import Signatures from "./components/PSP/Signatures";
import StepFour from "./components/PSP/StepFour/Step.Four.Component";
import StepOne from "./components/PSP/StepOne/Step.One.Component";
import StepSix from "./components/PSP/StepSix/Step.Six.Component";
import StepThree from "./components/PSP/StepThree/Step.Three.Component";
import StepTwoOne from "./components/PSP/StepTwo/Step.Two_One.Component";
import StepTwoTwo from "./components/PSP/StepTwo/Step.Two_Two.Component";
import Resources from "./components/Resources/Resources.Component";
import TicketBar from "./components/Ticket/TicketBar";
import Training from "./components/Training/Training.Component";
import Dashboard from "./components/Dashboard/Dashboard.Component";
import { AppStyles } from "./Styles";

const steps = [
	{ title: "Initial Determination", icon: <LibraryAddCheckOutLineIcon />, component: <StepOne /> },
	{
		title: "Project Activities",
		myLink: "/step2-1",
		icon: <DescriptionOutlinedIcon />,
		component: <StepTwoOne />, 
	},
	{
		title: "Project Information",
		myLink: "/step2-2",
		icon: <DescriptionOutlinedIcon />,
		component: <StepTwoTwo />, 
	},
	{ title: "Project Team", icon: <ListAltOutlinedIcon />, component: <StepThree /> }, 
	{ title: "Contacts & Emergency", icon: <PhoneInTalkOutlinedIcon />, component: <StepFour /> },
	{ title: "Review", icon: <PublishIcon />, component: <StepSix /> }, 
];

function App() {
	const objUser = useSelector((state) => state.AuthenReducer.userObj);
	const currentPsp = useSelector((state) => state.ProjectReducer);

	useEffect(() => {
		if (objUser) {
			bootstrapFromAPI.fetchActivityLevels();
			bootstrapFromAPI.fetchGuidanceDocuments();
			bootstrapFromAPI.fetchDeltekUserList();
			bootstrapFromAPI.fetchMyPSP(objUser.user ? objUser.user.userPrincipalName : "", 3);
		}
	}, [objUser]); 

	const classes = AppStyles();
	const maxWidthSize = window.innerWidth - 500;
	const routes = (
		<Router>
			<TicketBar
				id={"ticket-bar"}
				height={2000}
			/>
			<Header />
			<Switch>
				<Route
					path="/"
					exact>
					<HomeComponent />
				</Route>
				<Route path="/resources">
					<Resources />
				</Route>
				<Route path="/query">
					<AdminQuery />
				</Route>
				<Route path="/dashboard">
					<Dashboard />
				</Route>
				<Route path="/training">
					<Training />
				</Route>
				<Route path="/admin">
					<Admin />
				</Route>
				<Route path="/sign/:reportId">
					<Signatures />
				</Route>
				<Route path="/signatures/:pspId/:wbs1">
					<PSPSignatureList />
				</Route>
				<Route
					path="/step1"
					exact>
					<ProgressTracker
						currentStep={0}
						steps={steps}
					/>
				</Route>
				<Route
					path="/step1/:wsb1"
					exact>
					<ProgressTracker
						currentStep={0}
						steps={steps}
					/>
				</Route>
				<Route
					path="/mypsp"
					exact>
					<PSPList />
				</Route>
			</Switch>
		</Router>
	);

	// NOTE: this could use some refactoring so the UI is more responsive - 3/7 JS
	return (
		<div
			className={
				currentPsp.reviewMode && window.location.href.includes("step") && !currentPsp.isReadOnly
					? classes.rootbar
					: classes.root
			}
			style={
				currentPsp.reviewMode && window.location.href.includes("step") && !currentPsp.isReadOnly
					? { maxWidth: maxWidthSize }
					: { maxWidth: 1750 }
			}>
			<HasRole role="power-user">You are officially a power user</HasRole>
			{routes}
		</div>
	);
}

export default App;
