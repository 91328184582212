import { IconButton } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import { InfoOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import allActions from "../../redux/actions";

const GuidanceDocument = ({ objVal }) => {
	const dispatch = useDispatch();
	const [, refresh] = useState();
	const objProject = useSelector((state) => state.ProjectReducer.projectInfo);

	//Handle read only
	const isReadOnly = useSelector((state) => state.ProjectReducer.isReadOnly);

	function isChecked() {
		return objProject.projectGuidanceDocuments.find((gd) => gd.gdId === objVal.gdId && gd.selected) !== undefined;
	}

	// NOTE: Leaving code for when SAFE-384 is finalized - JS
	// const getLabel = () => {
	//   console.log(objVal)
	//   // if (objVal.tooltip !== null) {
	//   //   return (<><Typography variant='body2' data-tip data-for={"tt_" + objVal.gdId}> <Chip style={{ borderColor: 'green', backgroundColor: 'green', color: 'white' }} avatar={<InfoOutlined style={{ color: 'white' }} />} label='Low'>  </Chip> &nbsp; {objVal?.gdNumber + " " + objVal.gdName}</Typography > <ReactTooltip id={"tt_" + objVal.gdId} placement={"top"} ><span><IconButton aria-label="More information"><InfoOutlined style={{ color: 'green' }} /></IconButton>  Low Rating<br />{objVal.tooltip}</span></ReactTooltip></>)
	//   // } else {
	//     return (<Typography variant='body2'> {objVal?.gdNumber + " " + objVal.gdName}</Typography>)
	//   // }
	// }

	// Material UI Tooltip has a bug I cannot solve within a reasonable amoutn of time. Using this third party tooltip component instead.
	const getLabel = () => {
		if (objVal.tooltip !== null) {
			return (
				<>
					<Typography
						variant="body2"
						data-tip
						data-for={"tt_" + objVal.gdId}>
						{" "}
						<IconButton aria-label="More information">
							<InfoOutlined />
						</IconButton>{" "}
						{objVal?.gdNumber + " " + objVal.gdName}
					</Typography>
					<ReactTooltip
						id={"tt_" + objVal.gdId}
						placement={"top"}>
						<span>{objVal.tooltip}</span>
					</ReactTooltip>
				</>
			);
		} else {
			return <Typography variant="body2"> {objVal?.gdNumber + " " + objVal.gdName}</Typography>;
		}
	};

	const handleChange = (event) => {
		event.target.checked
			? (objProject.projectGuidanceDocuments.find((gd) => gd.gdId === objVal.gdId).selected = true)
			: (objProject.projectGuidanceDocuments.find((gd) => gd.gdId === objVal.gdId).selected = false);

		dispatch(allActions.ProjectActions.saved_Project(objProject));

		refresh({});
	};

	return (
		<>
			<FormControlLabel
				control={
					<Checkbox
						onClick={handleChange}
						name={`gdId` + objVal.gdId}
						color="secondary"
						checked={isChecked()}
						disabled={isReadOnly}
					/>
				}
				label={getLabel()}
			/>
			<hr />
		</>
	);
};

export default GuidanceDocument;
