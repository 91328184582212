import React, { useCallback, useState, useEffect } from "react";
import {
	Grid,
	IconButton,
	makeStyles,
	Modal,
	Paper,
	Typography,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Button,
	Tooltip,
	Fab,
} from "@material-ui/core";
import { Close, Description, Delete, GetApp } from "@material-ui/icons";
import { useDropzone } from "react-dropzone";
import AttachmentButton from "./AttachmentButton.Component";
import LoadingOverlay from "react-loading-overlay";
import bootstrapData from "../../bootstrapData";

const useStyles = makeStyles({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
});

const FileUpload = ({ wbs1, showAttachments }) => {
	const [showHide, setShowHide] = useState(false);
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [files, setFiles] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			await bootstrapData.getListOfFilesForSinglePSP(wbs1).then((response) => {
				setFiles(response.data);
			});
		};

		fetchData();
	}, [wbs1]);

	const onDrop = useCallback(
		(acceptedFiles) => {
			acceptedFiles.forEach((file) => {
				const reader = new FileReader();
				reader.onabort = () => console.log("file reading was aborted");
				reader.onerror = () => console.log("file reading has failed");
				reader.onload = () => {
					const fileData = new FormData();
					fileData.append("file", file);

					try {
						const res = async () => {
							setLoading(true);

							bootstrapData.postFileForSinglePSP(wbs1, fileData).then((response) => {
								if (response.status === 200) {
									bootstrapData.getListOfFilesForSinglePSP(wbs1).then((response) => {
										setFiles(response.data);
										setLoading(false);
									});
								}
							});
						};
						res();
					} catch (err) {
						console.log(err);
					}
				};
				reader.readAsArrayBuffer(file);
			});
		},
		[setFiles, wbs1],
	);

	const deleteFile = (fileName) => {
		setLoading(true);

		bootstrapData.deleteFileForSinglePSP(wbs1, fileName).then((response) => {
			if (response.status === 200) {
				bootstrapData.getListOfFilesForSinglePSP(wbs1).then((response) => {
					setFiles(response.data);
					setLoading(false);
				});
			}
		});
	};

	const downloadFile = (fileName) => {
		bootstrapData.downloadFileAttachedToPSP(wbs1, fileName).then((response) => {
			const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = downloadUrl;
			link.setAttribute("download", fileName);
			document.body.appendChild(link);
			link.click();
			link.remove();
		});
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop: (file) => onDrop(file),
		accept: {
			"application/pdf": [".pdf"],
		},
	});

	return (
		<>
			<Fab
				disabled={!showAttachments}
				color="secondary"
				aria-label="File Manager"
				onClick={() => setShowHide(true)}
				size="small">
				<AttachmentButton
					wbs1={wbs1}
					files={files}
				/>
			</Fab>
			<Modal
				open={showHide}
				className={classes.modal}>
				<Paper style={{ padding: 10, borderRadius: 10, textAlign: "center" }}>
					<Grid container>
						<Grid
							item
							xs={11}
							style={{ textAlign: "left" }}>
							<Typography style={{ fontSize: 24, fontWeight: "bolder", color: "#004361" }}>
								Manage PSP Attachements
							</Typography>
							<Typography variant="subtitle1">PDF files uploaded will be automatically appended to your PSP.</Typography>
						</Grid>
						<Grid
							item
							xs={1}
							style={{ textAlign: "center" }}>
							<IconButton
								size="small"
								aria-label="close"
								onClick={() => setShowHide(false)}>
								<Close />
							</IconButton>
						</Grid>
						<Grid
							item
							xs={12}>
							<LoadingOverlay
								active={loading}
								text="Loading, please wait..."
								spinner>
								<Paper
									{...getRootProps()}
									style={{ border: "2px dashed grey", padding: "5rem 0 5rem 0", marginTop: 10 }}>
									<>
										<input
											{...getInputProps()}
											height={300}
										/>
										<Typography variant="subtitle2">Drag &amp; drop files or</Typography>
										<Button
											style={{ marginLeft: 10, marginBottom: 10, backgroundColor: "#007C90" }}
											variant="contained"
											color="primary"
											onClick={() => getRootProps()}>
											Browse
										</Button>
										<br />
										<Typography variant="subtitle2">Requires .pdf file extension</Typography>
									</>
								</Paper>

								<List dense={true}>
									{files?.map((file) => (
										<ListItem key={file}>
											<ListItemIcon>
												<Description fontSize="small" />
											</ListItemIcon>
											<ListItemText
												primary={file?.split("/")[2] || file}
												secondary={null}
											/>
											<ListItemSecondaryAction>
												<Tooltip title="Download">
													<IconButton
														edge="end"
														aria-label="download"
														style={{ marginRight: 10 }}
														onClick={() => downloadFile(file?.split("/")[2])}>
														<GetApp />
													</IconButton>
												</Tooltip>
												<Tooltip title="Delete">
													<IconButton
														edge="end"
														aria-label="delete"
														onClick={() => deleteFile(file?.split("/")[2])}>
														<Delete />
													</IconButton>
												</Tooltip>
											</ListItemSecondaryAction>
										</ListItem>
									))}
								</List>
							</LoadingOverlay>{" "}
						</Grid>
					</Grid>{" "}
				</Paper>
			</Modal>
		</>
	);
};

export default FileUpload;
