import React, { useEffect, useState } from "react";
import bootstrapFromAPI from "../../bootstrapData";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";
import MyPSP from "../Control/MyPSP.Component";
import { RotateSpinner } from "react-spinners-kit";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const Dashboard = () => {
	const [loaded, setLoaded] = useState(false);
	const data = [];
	const regionalData = [];
	const [pieChartData, setPieChartData] = useState(data);
	const [barChartData, setBarChartData] = useState(regionalData);
	const [refreshChart, setRefreshChart] = useState(""); //setRefreshChart is just a dummy variable
	const [combinedProjectlist, setCombinedProjectList] = useState([]);
	const [projectlistWithAge, setProjectlistWithAge] = useState([]);

	const getRegion = (orgCode) => {
		let region = "";

		switch (orgCode) {
			case "NC":
				region = "Northern California";
				break;
			case "SC":
				region = "Southern California";
				break;
			case "NW":
				region = "Northwest";
				break;
			case "SE":
				region = "Southeast";
				break;
			case "CO":
				region = "Corporate";
				break;
			default:
				region = "N/A";
		}

		return region;
	};

	const getNumberOfDays = (begin) => {
		const date1 = new Date(begin);
		const date2 = new Date();

		// One day in milliseconds
		const oneDay = 1000 * 60 * 60 * 24;

		// Calculating the time difference between two dates
		const diffInTime = date2.getTime() - date1.getTime();

		// Calculating the no. of days between two dates
		const diffInDays = Math.round(diffInTime / oneDay);

		return diffInDays;
	};

	// eslint-disable-next-line
	useEffect(() => {
		let noFoundPsp;
		let noNCDeltekProjects = [];
		let noSCDeltekProjects = [];
		let noNWDeltekProjects = [];
		let noSEDeltekProjects = [];
		let projectList = [];
		bootstrapFromAPI.fetchDeltekProjectList().then((resp) => {
			noFoundPsp = resp.filter((project) => !project.hasPSP);
			noNCDeltekProjects = noFoundPsp.filter((pr) => pr.organization.substring(0, 2) === "NC");
			noSCDeltekProjects = noFoundPsp.filter((pr) => pr.organization.substring(0, 2) === "SC");
			noNWDeltekProjects = noFoundPsp.filter((pr) => pr.organization.substring(0, 2) === "NW");
			noSEDeltekProjects = noFoundPsp.filter((pr) => pr.organization.substring(0, 2) === "SE");
			projectList = resp;
		});

		bootstrapFromAPI.fetchAllPSP().then((PSP) =>
			//Total Project List From Deltek with Start Date Since 07-01-2021
			{
	
				// console.log("PSP", PSP);
				var TotalDeltekProjects = projectList.filter((pr) => new Date(pr.startDate) > new Date("07/01/2021"));
				let allPSP = PSP.filter((project) => new Date(project.projectStartDate) > new Date("07/01/2021"));

				//Project list from both Deltek + PSP
				let totalProjects = [];
				let project = {};
				let openPSP = []; // All open PSP
				let openPSPWithAge = [];

				// console.log("All PSP =", allPSP);

				openPSP = allPSP.filter((psp) => psp.projectStatuses[0].statusId < 3);

				//console.log('Open PSP', openPSP);

				openPSP.map((p) => {
					project.id = p.wbs1;
					project.projectName = p.projectName || "N/A";
					project.projectManager = TotalDeltekProjects.find((dtk) => dtk.wbs1 === p.wbs1)?.projectManager || "N/A";
					project.businessGroup = p.businessGroup;
					project.startDate = new Date(p.projectStartDate)?.toLocaleDateString() || "-";
					project.openDate = p.projectStatuses[p.projectStatuses.length - 1].statusDate;
					project.age = getNumberOfDays(p.projectStatuses[p.projectStatuses.length - 1].statusDate);
					project.status = p.currentStatus;
					project.region = getRegion(p.orgCode);
					openPSPWithAge.push(project);
					project = {};
					return "";
				});

				// console.log("PSP With Open Date", openPSPWithAge);
				setProjectlistWithAge(openPSPWithAge.filter((psp) => psp.age >= 30));

				//First restructure PSP project Info
				allPSP.map((p) => {
					project.id = p.wbs1;
					project.projectName = p.projectName || "N/A";
					project.projectManager = TotalDeltekProjects.find((dtk) => dtk.wbs1 === p.wbs1)?.projectManager || "N/A";
					project.businessGroup = p.businessGroup;
					project.startDate = new Date(p.projectStartDate)?.toLocaleDateString() || "-";
					project.status = p.currentStatus;
					project.region = getRegion(p.orgCode);
					totalProjects.push(project);
					project = {};
					return "";
				});

				//console.log('First Total =', totalProjects);

				//Any Deltek Project that doesn't have a PSP
				TotalDeltekProjects.map((dltk) => {
					if (allPSP.findIndex((x) => x.wbs1 === dltk.wbs1) === -1) {
						//This mean the project doesn't have a PSP yet
						project.id = dltk.wbs1;
						project.projectName = dltk.projectName;
						project.projectManager = dltk.projectManager;
						project.businessGroup = dltk.organizationName;
						project.startDate = new Date(dltk.startDate).toLocaleDateString() || "-";
						project.status = "No PSP";
						project.region = getRegion(dltk.organization.substring(0, 2));
						totalProjects.push(project);
						project = {};
					}

					return "";
				});

				// console.log("Second Total =", totalProjects);

				setCombinedProjectList(totalProjects);

				regionalData.push({
					Region: "NC",
					NoPSP: noNCDeltekProjects.length,
					NoFieldWork: allPSP.filter(
						(psp) => psp.currentStatus === "No Fieldwork" && psp.businessGroup.substring(0, 2) === "NC",
					).length,
					InProgress: allPSP.filter(
						(psp) => psp.currentStatus === "In Progress" && psp.businessGroup.substring(0, 2) === "NC",
					).length,
					Submitted: allPSP.filter(
						(psp) => psp.currentStatus === "Submitted" && psp.businessGroup.substring(0, 2) === "NC",
					).length,
					Approved: allPSP.filter(
						(psp) => psp.currentStatus === "Approved" && psp.businessGroup.substring(0, 2) === "NC",
					).length,
					//color:['#9F1E49','#004562','#FFE17F','#FFE17F','#00A79D']
				});

				regionalData.push({
					Region: "SC",
					NoPSP: noSCDeltekProjects.length,
					NoFieldWork: allPSP.filter(
						(psp) => psp.currentStatus === "No Fieldwork" && psp.businessGroup.substring(0, 2) === "SC",
					).length,
					InProgress: allPSP.filter(
						(psp) => psp.currentStatus === "In Progress" && psp.businessGroup.substring(0, 2) === "SC",
					).length,
					Submitted: allPSP.filter(
						(psp) => psp.currentStatus === "Submitted" && psp.businessGroup.substring(0, 2) === "SC",
					).length,
					Approved: allPSP.filter(
						(psp) => psp.currentStatus === "Approved" && psp.businessGroup.substring(0, 2) === "SC",
					).length,
				});

				regionalData.push({
					Region: "NW",
					NoPSP: noNWDeltekProjects.length,
					NoFieldWork: allPSP.filter(
						(psp) =>
							psp.currentStatus === "No Fieldwork" &&
							(psp.businessGroup.substring(0, 3) === "SEA" || psp.businessGroup.substring(0, 3) === "POR"),
					).length,
					InProgress: allPSP.filter(
						(psp) =>
							psp.currentStatus === "In Progress" &&
							(psp.businessGroup.substring(0, 3) === "SEA" || psp.businessGroup.substring(0, 3) === "POR"),
					).length,
					Submitted: allPSP.filter(
						(psp) =>
							psp.currentStatus === "Submitted" &&
							(psp.businessGroup.substring(0, 3) === "SEA" || psp.businessGroup.substring(0, 3) === "POR"),
					).length,
					Approved: allPSP.filter(
						(psp) =>
							psp.currentStatus === "Approved" &&
							(psp.businessGroup.substring(0, 3) === "SEA" || psp.businessGroup.substring(0, 3) === "POR"),
					).length,
					//color:['#9F1E49','#004562','#FFE17F','#FFE17F','#00A79D']
				});

				regionalData.push({
					Region: "SE",
					NoPSP: noSEDeltekProjects.length,
					NoFieldWork: allPSP.filter(
						(psp) =>
							psp.currentStatus === "No Fieldwork" &&
							psp.businessGroup.substring(0, 2) === "SE" &&
							psp.businessGroup.substring(0, 3) !== "SEA",
					).length,
					InProgress: allPSP.filter(
						(psp) =>
							psp.currentStatus === "In Progress" &&
							psp.businessGroup.substring(0, 2) === "SE" &&
							psp.businessGroup.substring(0, 3) !== "SEA",
					).length,
					Submitted: allPSP.filter(
						(psp) =>
							psp.currentStatus === "Submitted" &&
							psp.businessGroup.substring(0, 2) === "SE" &&
							psp.businessGroup.substring(0, 3) !== "SEA",
					).length,
					Approved: allPSP.filter(
						(psp) =>
							psp.currentStatus === "Approved" &&
							psp.businessGroup.substring(0, 2) === "SE" &&
							psp.businessGroup.substring(0, 3) !== "SEA",
					).length,
					//color:['#9F1E49','#004562','#FFE17F','#FFE17F','#00A79D']
				});

				//console.log('Regional Data =', regionalData);

				data.push({
					id: "No PSP",
					label: "No PSP",
					value: noFoundPsp.length,
					color: "#8F8F8F",
				});

				data.push({
					id: "No Field Work",
					label: "No Field Work",
					value: allPSP.filter((psp) => psp.currentStatus === "No Fieldwork").length,
					color: "#9F1E49",
				});

				data.push({
					id: "In Progress",
					label: "In Progress",
					value: allPSP.filter((psp) => psp.currentStatus === "In Progress").length,
					color: "#004562",
				});

				data.push({
					id: "Submitted",
					label: "Submitted",
					value: allPSP.filter((psp) => psp.currentStatus === "Submitted").length,
					color: "#5e449b",
				});

				data.push({
					id: "Approved",
					label: "Approved",
					value: allPSP.filter((psp) => psp.currentStatus === "Approved").length,
					color: "#00A79D",
				});

				setBarChartData(regionalData);
				setPieChartData(data);

				//console.log('barChartData =', barChartData);

				//setRefreshChart is just a dummy variable
				if (refreshChart) {
					setRefreshChart("Data");
				}

				setRefreshChart("Data");
				setLoaded(true);
			},
		);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const defaultColumns = [
		//{ field: 'id', headerName: 'Project ID',width:175},
		{ field: "region", headerName: "Region", width: 150 },
		{ field: "id", headerName: "Project Number", width: 150 },
		{ field: "projectName", headerName: "Project Name", width: 200 },
		{ field: "projectManager", headerName: "Project Manager", width: 230 },
		{ field: "businessGroup", headerName: "Organization", width: 250 },
		{ field: "startDate", headerName: "Start Date", width: 200 },
		{ field: "status", headerName: "Status", width: 150 },
	];

	const ageColumns = [
		//{ field: 'id', headerName: 'Project ID',width:175},
		{ field: "region", headerName: "REGION", width: 150 },
		{ field: "id", headerName: "Project", width: 150 },
		{ field: "projectName", headerName: "Project Name", width: 200 },
		{ field: "projectManager", headerName: "Project Manager", width: 230 },
		{ field: "businessGroup", headerName: "Organization", width: 250 },
		{ field: "startDate", headerName: "Start Date", width: 150 },
		{ field: "status", headerName: "Status", width: 150 },
		{ field: "age", headerName: "Age" },
	];

	//Extract In Progress PSP
	const objGridInfo = {};
	objGridInfo.rows = combinedProjectlist ? combinedProjectlist : [];
	objGridInfo.mypsptitle = "Project Safety Plan";
	objGridInfo.columns = defaultColumns;

	//Grid with Age
	const objGridWithAgeInfo = {};
	objGridWithAgeInfo.rows = projectlistWithAge ? projectlistWithAge : [];
	objGridWithAgeInfo.mypsptitle = "Project Safety Plan With Age";
	objGridWithAgeInfo.columns = ageColumns;

	return !loaded ? (
		<div style={{ padding: "2rem" }}>
			<RotateSpinner
				size={45}
				color="#686769"
			/>
		</div>
	) : (
		<>
			<Card>
				<CardContent>
					<div style={{ height: 400 }}>
						<ResponsivePie
							data={pieChartData}
							margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
							innerRadius={0.5}
							padAngle={0.7}
							cornerRadius={3}
							colors={{ datum: "data.color" }}
							borderWidth={1}
							borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
						/>
					</div>

					<div style={{ height: 400 }}>
						<ResponsiveBar
							data={barChartData}
							keys={["NoPSP", "NoFieldWork", "InProgress", "Submitted", "Approved"]}
							indexBy="Region"
							colors={["#8F8F8F", "#9F1E49", "#004562", "#5e449b", "#00A79D"]}
							margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
							padding={0.4}
							valueScale={{ type: "linear" }}
							animate={true}
							enableLabel={true}
							axisTop={null}
							axisRight={null}
							axisLeft={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: "Number of PSP",
								legendPosition: "middle",
								legendOffset: -40,
							}}
							legends={[
								{
									dataFrom: "keys",
									anchor: "bottom-right",
									direction: "column",
									justify: false,
									translateX: 120,
									translateY: 0,
									itemsSpacing: 2,
									itemWidth: 100,
									itemHeight: 20,
									itemDirection: "left-to-right",
									itemOpacity: 0.85,
									itemTextColor: "#000000",
									symbolSize: 20,
									effects: [
										{
											on: "hover",
											style: {
												itemOpacity: 1,
											},
										},
									],
								},
							]}
						/>
					</div>
				</CardContent>
			</Card>
			<MyPSP
				objPSPList={objGridInfo}
				hidePagination={true}
			/>
			<br />
			<MyPSP
				objPSPList={objGridWithAgeInfo}
				hidePagination={true}
			/>
			<br />
		</>
	);
};

export default Dashboard;
