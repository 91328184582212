import React from "react";
import { Fab, Tooltip } from "@material-ui/core";
import { MenuBook, Edit } from "@material-ui/icons";
import Report from "@material-ui/icons/Assessment";
import { useHistory } from "react-router-dom";
import { API_URI_BASE } from "../../config";

export default function GridIconButton({ params, type, disabled, toolTipTitle }) {
	const history = useHistory();

	const onClick = (type) => {
		switch (type) {
			case "acknowledgement":
				history.push({
					pathname: `/signatures/${params.row.id}/${params.row.projectNumber}`,
				});
				break;
			case "report":
				window.open(`${API_URI_BASE()}/Report/${params.row.id}`, "_blank");
				break;
			case "edit":
				history.push({
					pathname: `/step1/${params.row.projectNumber}`,
				});

				break;

			default:
				break;
		}
	};

	let Icon;
	switch (type) {
		case "acknowledgement":
			Icon = MenuBook;
			break;
		case "report":
			Icon = Report;
			break;
		case "edit":
			Icon = Edit;
			break;
		default:
			break;
	}

	return (
		<Tooltip title={toolTipTitle || ""}>
			<Fab
				disabled={disabled}
				color="secondary"
				aria-label="edit"
				onClick={() => onClick(type)}
				size="small">
				<Icon />
			</Fab>
		</Tooltip>
	);
}
