/*
    Author : Peter Ross
    Description : Simple menu for PSP
*/
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Button } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import { useHistory } from "react-router-dom";

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import HasRole from "../Authentication/HasRole";

const theme = createTheme({
	palette: {
		secondary: {
			main: "#004361",
		},
	},
});

export default function PSPMenu({ bubbleEvt }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const history = useHistory();
	const [menu, setMenu] = React.useState();
	const navAndClose = (url) => {
		setAnchorEl(null);
		history.push(url);
	};
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (targetURL) => {
		setAnchorEl(null);
		handleNavigation(targetURL);
	};

	const handleNavigation = (url) => {
		if (bubbleEvt) bubbleEvt();
		switch (url) {
			case "Home":
				history.push("/");
				break;
			case "Create New PSP":
				history.push("/step1");
				break;
			case "View My PSP":
				history.push("/mypsp");
				break;
			case "Resources":
				history.push("/resources");
				break;
			case "Training":
				history.push("/training");
				break;
			default:
				break;
		}
	};

	return (
		<MuiThemeProvider theme={theme}>
			<AppBar
				position="static"
				color="secondary">
				<Toolbar>
					<div>
						<Button
							onClick={() => handleNavigation("Home")}
							style={{ padding: 20, backgroundColor: "#004361", color: "#FFF" }}>
							HOME
						</Button>
						<Button
							style={{ paddingRight: 20, backgroundColor: "#004361", color: "#FFF" }}
							aria-haspopup="true"
							onClick={(evt) => {
								setMenu("psp");
								handleClick(evt);
							}}>
							PROJECT SAFETY PLAN
						</Button>
						<Menu
							id="simple-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl) && menu === "psp"}
							onClose={handleClose}
							getContentAnchorEl={null}
							anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
							<MenuItem
								onClick={() => handleClose("Create New PSP")}
								style={{ color: "#5E9696" }}>
								Create New PSP
							</MenuItem>
							<MenuItem
								onClick={() => handleClose("View My PSP")}
								style={{ color: "#5E9696" }}>
								View My PSP
							</MenuItem>
						</Menu>
						<Button
							style={{ paddingRight: 20, backgroundColor: "#004361", color: "#FFF" }}
							onClick={() => handleClose("Resources")}>
							RESOURCES
						</Button>
						<Button
							style={{ paddingRight: 50, backgroundColor: "#004361", color: "#FFF" }}
							onClick={() => handleClose("Training")}>
							TRAINING
						</Button>
						<HasRole role="admin-menu">
							<Button
								style={{ paddingRight: 20, backgroundColor: "#004361", color: "#FFF" }}
								aria-haspopup="true"
								onClick={(evt) => {
									setMenu("admin");
									handleClick(evt);
								}}>
								ADMIN TOOLS
							</Button>
							<Menu
								id="admin-menu"
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl) && menu === "admin"}
								onClose={handleClose}
								getContentAnchorEl={null}
								anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
								<HasRole role="admin-queryPage">
									<MenuItem
										onClick={() => navAndClose("/query")}
										style={{ color: "#5E9696" }}>
										Query Page
									</MenuItem>
									<MenuItem
										onClick={() => navAndClose("/dashboard")}
										style={{ color: "#5E9696" }}>
										Dashboard
									</MenuItem>
								</HasRole>

								<HasRole role="admin-users">
									<MenuItem
										onClick={() => navAndClose("/userAdmin")}
										style={{ color: "#5E9696" }}>
										User Administration
									</MenuItem>
									<MenuItem
										onClick={() => navAndClose("/dashboard")}
										style={{ color: "#5E9696" }}>
										Dashboard
									</MenuItem>
								</HasRole>

								<HasRole role="admin-reports">
									<MenuItem
										onClick={() => navAndClose("/reports")}
										style={{ color: "#5E9696" }}>
										Reports
									</MenuItem>
									<MenuItem
										onClick={() => navAndClose("/dashboard")}
										style={{ color: "#5E9696" }}>
										Dashboard
									</MenuItem>
								</HasRole>

								<HasRole role="admin-keycloak">
									<MenuItem
										onClick={() =>
											(window.location.href =
												"https://identity.esassoc.net:8443/auth/admin/master/console/#/realms/eSafety/users")
										}
										style={{ color: "#5E9696" }}>
										Identity User Management
									</MenuItem>
								</HasRole>
							</Menu>
						</HasRole>
					</div>
				</Toolbar>
			</AppBar>
		</MuiThemeProvider>
	);
}
