import { Project } from "../../models/project";

const initialState = {
	projectInfo: Project,
	projectState: 1,
	reviewMode: false,
	isProcessing: false,
	isError: false,
	isReadOnly: false,
	isReadyToSubmit: false,
	isReadyToApprove: false,
};
function getProjectState(project, state) {
	if (!project) return -1;
	if (!state) return 1;
	if (!project) {
		return state.projectState;
	}

	if (!project.projectStatuses) {
		return state.projectState;
	}

	project.projectStatuses.sort((a, b) => b.projectStatusId - a.projectStatusId);
	return project.projectStatuses[0].statusId;
}

const ProjectReducer = (state = initialState, action) => {
	switch (action.type) {
		case "FETCH_PROJECT": {
			return {
				projectInfo: Project,
				isProcessing: false,
				isError: false,
				projectState: getProjectState(action.payload, state),
				reviewMode: getProjectState(action.payload, state) === 2,
				isReadOnly: state.isReadOnly,
				isReadyToSubmit: false,
				isReadyToApprove: false,
			};
		}
		case "RECEIVED_PROJECT": {
			return {
				projectInfo: action.payload,
				projectState: getProjectState(action.payload, state),
				reviewMode: getProjectState(action.payload, state) === 2,
				isProcessing: false,
				isError: false,
				isReadOnly: state.isReadOnly,
				isReadyToSubmit: false,
				isReadyToApprove: false,
			};
		}

		case "FETCH_PROJECT_ERROR": {
			return {
				projectInfo: {},
				isProcessing: false,
				isError: true,
				projectState: getProjectState(action.payload, state),
				reviewMode: getProjectState(action.payload, state) === 2,
				isReadOnly: state.isReadOnly,
				isReadyToSubmit: false,
				isReadyToApprove: false,
			};
		}

		case "CLEAR_PROJECT": {
			return initialState;
		}

		case "SAVE_PROJECT": {
			return {
				projectInfo: {},
				isProcessing: false,
				isError: false,
				projectState: getProjectState(action.payload, state),
				reviewMode: getProjectState(action.payload, state) === 2,
				isReadOnly: state.isReadOnly,
				isReadyToSubmit: false,
				isReadyToApprove: false,
			};
		}

		case "SAVED_PROJECT": {
			return {
				projectInfo: action.payload,
				projectState: getProjectState(action.payload, state),
				reviewMode: getProjectState(action.payload, state) === 2,
				isProcessing: false,
				isError: false,
				isReadOnly: state.isReadOnly,
				isReadyToSubmit: false,
				isReadyToApprove: false,
			};
		}

		case "SAVE_PROJECT_ERROR": {
			return {
				projectInfo: {},
				isProcessing: false,
				isError: false,
				projectState: getProjectState(action.payload, state),
				reviewMode: getProjectState(action.payload, state) === 2,
				isReadOnly: state.isReadOnly,
				isReadyToSubmit: false,
				isReadyToApprove: false,
			};
		}

		case "SET_PROJECT_READONLY": {
			return {
				projectInfo: state.projectInfo,
				projectState: state.projectState,
				reviewMode: state.reviewMode,
				isProcessing: false,
				isError: false,
				isReadOnly: action.payload,
				isReadyToSubmit: false,
				isReadyToApprove: false,
			};
		}

		case "SET_PROJECT_READY_TO_SUBMIT": {
			return {
				...state,
				isReadyToSubmit: action.payload,
			};
		}

		case "SET_PROJECT_READY_APPROVE": {
			return {
				...state,
				isReadyToApprove: action.payload,
			};
		}

		default: {
			return state;
		}
	}
};

export default ProjectReducer;
