import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { RotateSpinner } from "react-spinners-kit";
import { StepTwoStyles } from "../../../Styles";
import TextAreaWithHint from "../../Control/TextArea.WithHint.Component";
import { useSelector, useDispatch } from "react-redux";
import { HazardSummary } from "../../../models/config";
import allActions from "../../../redux/actions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TicketScope from "../../Ticket/ticketScope";
import { clearScopes } from "../../../redux/reducers/ticketReducer";
import VersionCheck from "../../Control/VersionCheck";

const StepTwo_Two = () => {
	const classes = StepTwoStyles();
	const currentPsp = useSelector((state) => state.ProjectReducer);
	const isReadOnly = useSelector((state) => state.ProjectReducer.isReadOnly);
	const [loaded, setLoaded] = useState(false);
	const dispatch = useDispatch();
	const [, setState] = useState();

	useEffect(() => {
		dispatch(clearScopes());
		setLoaded(true);
	}, [dispatch]);

	const updateRequest = () => {
		setState({});
	};

	const replaceNullValue = (input) => {
		return input === null ? "" : input;
	};

	HazardSummary.value = replaceNullValue(currentPsp.projectInfo.site.hazardSum);

	const handleChange = (event) => {
		if (event.target.checked) {
			currentPsp.projectInfo.site.multiPersonRq = true;
		} else {
			currentPsp.projectInfo.site.multiPersonRq = false;
		}

		dispatch(allActions.ProjectActions.saved_Project(currentPsp.projectInfo));
		setState({});
	};

	return !loaded ? (
		<div style={{ padding: "2rem" }}>
			<RotateSpinner
				size={45}
				color="#686769"
			/>
		</div>
	) : (
		<>
			<VersionCheck />
			<div className={classes.root}>
				<Grid
					container
					spacing={3}>
					<Grid
						item
						xs={12}></Grid>
					<Grid
						item
						xs={12}>
						<Card>
							<CardHeader
								className={classes.cardHeader}
								title="Step 2-2: Project Information"
							/>
							<br />
							<CardContent>
								<FormControlLabel
									control={
										<Checkbox
											checked={currentPsp.projectInfo.site.multiPersonRq === true}
											onChange={handleChange}
											name="multiPersonRq"
											disabled={isReadOnly}
										/>
									}
									label="Check this box if two people are required for safety reasons."
								/>
								<br />
								<br />
								<TicketScope scope="step2_siteNameLocation">
									<TextAreaWithHint
										globalUpdate={updateRequest}
										key="siteNameLocation"
										TextAreaInfo={{
											value: replaceNullValue(currentPsp.projectInfo.site.siteNameLocation),
											name: "siteNameLocation",
											label: "Site Name & Location",
											rows: 4,
											example: "",
											hint: "Include address, intersections, and/or a description of project location.",
											required: true,
										}}
									/>
								</TicketScope>
								<TicketScope scope="step2_sow">
									<TextAreaWithHint
										globalUpdate={updateRequest}
										key="sow"
										TextAreaInfo={{
											value: replaceNullValue(currentPsp.projectInfo.site.sow),
											name: "sow",
											label: "Scope of Work",
											rows: 5,
											example: "",
											hint: "Include a general description of the overall project and field work.",
											required: true,
										}}
									/>
								</TicketScope>
								<TicketScope scope="step2_siteDescription">
									<TextAreaWithHint
										globalUpdate={updateRequest}
										key="siteDescription"
										TextAreaInfo={{
											value: replaceNullValue(currentPsp.projectInfo.site.siteDescription),
											name: "siteDescription",
											label: "Site Description",
											rows: 6,
											example: "",
											hint: "Include a general description of the project site and include descriptions of field work.",
											required: true,
										}}
									/>
								</TicketScope>
								<TicketScope scope="step2_hazardSum">
									<TextAreaWithHint
										globalUpdate={updateRequest}
										key="hazardSum"
										TextAreaInfo={HazardSummary}
									/>
								</TicketScope>
								<TicketScope scope="step2_additionalConsiderations">
									<TextAreaWithHint
										globalUpdate={updateRequest}
										key="additionalConsiderations"
										TextAreaInfo={{
											value: currentPsp.projectInfo.addConsider ? currentPsp.projectInfo.addConsider : "",
											name: "additionalConsiderations",
											label: "Additional Considerations",
											rows: 6,
											example: "",
											required: false,
											hint: "What are the 3 things staff need to know about this site to minimize their exposure?",
										}}
									/>
								</TicketScope>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default StepTwo_Two;
