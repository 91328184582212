import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import dotenv from "dotenv";
import { Provider } from "react-redux";
import { dataStore, persistor } from "./redux/reduxStore";
import { PersistGate } from "redux-persist/integration/react";
import IdentityProvider from "./components/Authentication/IdentityProvider";
import { LicenseInfo } from "@mui/x-license-pro";
import { createTheme, MuiThemeProvider } from "@material-ui/core";

LicenseInfo.setLicenseKey(
	"a695c37bacf7c52a6fbd94e5f6ea23ccTz03NzU4MixFPTE3MzAwNjIwNjYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
);

const theme = createTheme({
	palette: {
		secondary: {
			main: "#007C90",
		},
	},
});

const container = document.getElementById("root");
const root = createRoot(container);
dotenv.config();
root.render(
	<Provider store={dataStore}>
		<PersistGate
			loading={null}
			persistor={persistor}>
			<IdentityProvider>
				<MuiThemeProvider theme={theme}>
					<App />
				</MuiThemeProvider>
			</IdentityProvider>
		</PersistGate>
	</Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
