import { Provider as KeycloakProvider } from "react-keycloak-context";
import React from "react";
import keycloakConfig from "./keycloak-config";
import allActions from "../../redux/actions";
import { dataStore } from "../../redux/reduxStore";

export default function IdentityProvider(props) {
	const [initdone, setInitDone] = React.useState(false);
	const init = (evt) => {
		//set user to redux.
		localStorage.setItem("token", evt.token);
		var details = {
			userName: evt.tokenParsed.name,
			user: {
				mail: evt.tokenParsed.email,
				userPrincipalName: evt.tokenParsed.email,
				displayName: evt.tokenParsed.name,
				token: evt.token,
			},
		};

		dataStore.dispatch(allActions.AuthenActions.received_Authen(details));
		setInitDone(true);

		evt.loadUserProfile();
		evt.loadUserInfo();
	};
	
	return (
		<KeycloakProvider
			initOptions={{
				onLoad: "login-required",
				promiseType: "native",
				checkLoginIframe: false,
			}}
			config={keycloakConfig}
			tokenKey={"kc-token"}
			refreshTokenKey={"kc-refresh"}
			onInit={init}>
			{initdone && props.children}
		</KeycloakProvider>
	);
}
