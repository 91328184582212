import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-pro";
import { MyPSPStyles } from "../../Styles";


const CustomToolbar = () => {
	return (
		<GridToolbarContainer>
			<GridToolbarExport />
		</GridToolbarContainer>
	);
};

const MyPSP = ({ objPSPList, hidePagination }) => {
	const classes = MyPSPStyles();
	
	return (
		<>
			<br />
			<div>
				<Grid
					container
					spacing={3}>
					<Grid
						item
						xs={12}>
						<Card>
							<CardHeader
								className={classes.cardHeader}
								title={objPSPList.mypsptitle}
							/>
							<CardContent
								style={{ height: 400, width: "97%" }}
								{...objPSPList}>
								<DataGridPro
									initialState={{
										pagination: {
											paginationModel: { pageSize: 25, page: 0 },
										},
									}}
									getRowId={(row) => {
										return row._id ? row._id : row.id;
									}}
									rows={objPSPList.rows}
									columns={objPSPList.columns}
									pagination={!hidePagination}
									slots={{
										toolbar: CustomToolbar,
									}}
									{...objPSPList}
								/>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default MyPSP;
