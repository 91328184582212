import React from "react";
import Grid from "@material-ui/core/Grid";
import { InputLabel } from "@material-ui/core";
import { TextareaAutosize } from "@material-ui/core";
import { TextAreaWithHintStyles } from "../../Styles";
import { useSelector, useDispatch } from "react-redux";
import allActions from "../../redux/actions";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const TextAreaWithHint = ({ TextAreaInfo, globalUpdate }) => {
	const [open, setOpen] = React.useState(false);
	const dispatch = useDispatch();
	const Project = useSelector((state) => state.ProjectReducer.projectInfo);
	const isReadOnly = useSelector((state) => state.ProjectReducer.isReadOnly);
	const Site = Project.site;
	const classes = TextAreaWithHintStyles();
	const textAreaInfo = TextAreaInfo;

	const handleChange = (event) => {
		if (event.target.id === "additionalConsiderations") {
			Project.addConsider = event.target.value;
			dispatch(allActions.ProjectActions.saved_Project(Project));
			globalUpdate();
		} else {
			Site[event.target.id] = event.target.value;
			Project.site = Site;
			dispatch(allActions.ProjectActions.saved_Project(Project));
			globalUpdate();
		}
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Grid
				container
				spacing={4}>
				<Grid
					item
					xs={8}>
					{(textAreaInfo.value === undefined || textAreaInfo.value === "") && textAreaInfo.required ? (
						<InputLabel className={classes.formLabelRed}>{textAreaInfo.label} is a required field</InputLabel>
					) : (
						<InputLabel className={classes.formLabelGreen}>{textAreaInfo.label}</InputLabel>
					)}
					<TextareaAutosize
						className={classes.fullWidthTextArea}
						maxRows={textAreaInfo.rows}
						minRows={textAreaInfo.rows}
						name={textAreaInfo.name}
						id={textAreaInfo.name}
						value={textAreaInfo.value}
						onChange={handleChange}
						disabled={isReadOnly}
					/>
				</Grid>
				<Grid
					item
					xs={4}
					onClick={() => (textAreaInfo.example !== "" ? handleOpen(true) : "")}
					style={{ cursor: "pointer" }}>
					<InputLabel>
						<br />
						<br />
					</InputLabel>
					<TextareaAutosize
						className={classes.fieldHint}
						maxRows={textAreaInfo.rows}
						minRows={textAreaInfo.rows}
						disabled={true}
						defaultValue={textAreaInfo.hint}
					/>
				</Grid>
			</Grid>
			<br />
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="md">
				<DialogTitle
					id="alert-dialog-title"
					className={classes.dialogTitle}>
					{textAreaInfo.title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{textAreaInfo.example}</DialogContentText>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default TextAreaWithHint;
