/*
    Author : Alan Nguyen
    Description : 
        1   Extract each of the PSP array from the list of PSP
        2   Only render those grid that actually have records in it
*/

import React, { useEffect, useState } from "react";
import MyPSP from "../../Control/MyPSP.Component";
import { useSelector, useDispatch } from "react-redux";
import allActions from "../../../redux/actions";
import bootstrapFromAPI from "../../../bootstrapData";
import Fab from "@material-ui/core/Fab";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FieldStaff from "../../Control/FieldStaff.Component";
import { PSPListStyles } from "../../../Styles";
import TextField from "@material-ui/core/TextField";
import FileUpload from "../../Control/FileUpload.Component";
import { iconColumnStyles } from "../../../utilities/columnHelpers";
import GridIconButton from "../../Control/GridIconButton.Component";

const PSPList = () => {
	const classes = PSPListStyles();
	const objUser = useSelector((state) => state.AuthenReducer.userObj);
	const dispatch = useDispatch();
	const [, setState] = useState();
	//Use state to get the initial object
	const [objProject, setObjProject] = useState();
	const [clearValue, setClearValue] = useState("");
	const [fieldStaff, setFieldStaff] = useState(null);
	let users = useSelector((state) => state.DeltekUserListReducer.deltekUserList);

	const fetchProject = async (wbs1) => {
		await bootstrapFromAPI.fetchProject(wbs1).then((result) => {
			console.log("this is the result ::", result);
			setObjProject(result);
		});

		setState({});
		handleOpen();
	};

	const captureInput = (evtData) => {
		setClearValue(evtData.target.value);
	};

	const handleAddFieldStaff = async (event, value) => {
		//Adding field staff to the array
		setFieldStaff(null);
		setState({});

		if (value !== undefined && value !== null) {
			value.pspRoleId = 3;
			const fieldStaff = value;
			objProject.projectUsers.filter((user) => user.pspRoleId === 3).find((user) => user.userId === fieldStaff.userId)
				? console.log("User Already Exist")
				: objProject.projectUsers.push(fieldStaff);
			dispatch(allActions.ProjectActions.saved_Project(objProject));

			//This next block of code will be common code for all screens
			if ((await bootstrapFromAPI.saveProject(objProject)) === "OK") {
				console.log("Adding field staff was OK");
			} else {
				console.log("Error Saving to API");
			}

			//Clear out option box.
			document.body.focus();
			setClearValue("");
		}
	};

	const editCommand = {
		...iconColumnStyles,
		hide: false,
		field: "edit",
		headerName: "Edit",
		flex: 0.25,
		renderCell: (params) => (
			<GridIconButton
				params={params}
				type="edit"
			/>
		),
	};

	const reportCommand = {
		...iconColumnStyles,
		hide: false,
		field: "report",
		headerName: "Report",
		flex: 0.25,
		renderCell: (params) => (
			<GridIconButton
				params={params}
				type="report"
			/>
		),
	};

	const acknowledgementCommand = {
		...iconColumnStyles,
		hide: false,
		field: "acknowledgement",
		headerName: "Acknowledgement",
		flex: 0.35,
		renderCell: (params) => (
			<GridIconButton
				params={params}
				type="acknowledgement"
				disabled={params.row.statusId === 4}
				toolTipTitle={
					params.row.statusId === 4
						? "This is a no fieldwork project and does not require acknowledgement."
						: "Open acknowledgement page"
				}
			/>
		),
	};

	const addFieldStaffCommand = {
		...iconColumnStyles,
		hide: false,
		field: "addFieldStaff",
		headerName: "Add Field Staff",
		headerAlign: "center",
		flex: 0.25,

		renderCell: (params) => {
			const addFieldStaffClick = () => {
				console.log("Project Number =", params.row.projectNumber);
				fetchProject(params.row.projectNumber);
			};

			return (
				<Fab
					color="secondary"
					aria-label="edit"
					onClick={addFieldStaffClick}
					size="small"
					disabled={!params.row.showAddUser}>
					<PersonAddIcon />
				</Fab>
			);
		},
	};

	useEffect(() => {
		bootstrapFromAPI.fetchMyPSP(objUser.user ? objUser.user.userPrincipalName : "", -1);
		dispatch(allActions.ProjectActions.clear_Project());
	}, [objUser, dispatch]);

	const defaultColumns = [
		{ field: "org", headerName: "Region", width: 150 },
		{ field: "projectNumber", headerName: "Project Number", width: 175 },
		{ field: "projectName", headerName: "Project Name", flex: 0.75 },
		{ field: "lastUpdated", headerName: "Date Updated", flex: 0.4 },
		{
			...iconColumnStyles,
			field: "wbs1",
			headerName: "Attachments",
			flex: 0.25,
			renderCell: (params) => (
				<FileUpload
					wbs1={params.row.projectNumber}
					showAttachments={params.row.showAttachments}
				/>
			),
		},
	];

	const inProgressColumns = defaultColumns.slice();
	inProgressColumns.push(editCommand);

	const approvedColumns = defaultColumns.slice();
	approvedColumns.push(reportCommand);
	approvedColumns.push(acknowledgementCommand);
	approvedColumns.push(addFieldStaffCommand);

	var rowData = useSelector((state) => state.MyPSPReducer.MyPSP);

	//Extract In Progress PSP
	const objInProgress = {};
	objInProgress.rows = rowData ? rowData.filter((obj) => obj.statusId === 1) : [];
	objInProgress.mypsptitle = "In Progress";
	objInProgress.columns = inProgressColumns;

	console.log("objInProgress==>", objInProgress.rows);
	//Extract In Submitted
	const objSubmitted = {};
	objSubmitted.rows = rowData ? rowData.filter((obj) => obj.statusId === 2) : [];
	objSubmitted.mypsptitle = "Submitted";
	objSubmitted.columns = inProgressColumns;

	//Extract Approved PSP
	const objApproved = {};
	objApproved.rows = rowData ? rowData.filter((obj) => obj.statusId === 3 || obj.statusId === 4) : [];

	console.log("Approved", objApproved.rows);

	objApproved.mypsptitle = "Approved";
	objApproved.columns = approvedColumns;

	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	//This is just to update the child component
	function updateUi() {
		setState({});
	}

	//Rendor the grids
	return (
		<>
			<MyPSP objPSPList={objInProgress} />
			<br />
			<MyPSP objPSPList={objSubmitted} />
			<br />
			<MyPSP objPSPList={objApproved} />
			<br />

			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth={true}
				maxWidth={"md"}>
				<DialogTitle
					id="alert-dialog-title"
					className={classes.dialogTitle}>
					Adding Field Staff
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<Grid
							container
							spacing={1}>
							<Grid
								item
								xs={4}>
								<Autocomplete
									inputValue={clearValue}
									id="fieldStaff"
									options={users}
									value={fieldStaff}
									getOptionLabel={(option) => option.fullName}
									onInput={captureInput}
									onChange={handleAddFieldStaff}
									renderInput={(params) => {
										return (
											<>
												{" "}
												<TextField
													{...params}
													placeholder="Type and select to add staff"
													label="Field Staff"
													id="selectedFieldStaff"
												/>
											</>
										);
									}}
									disabled={false}
								/>
							</Grid>
							<Grid
								item
								xs={8}>
								<Grid
									container
									spacing={1}>
									{objProject && objProject.projectUsers !== undefined
										? objProject.projectUsers
												.filter((user) => user.pspRoleId === 3)
												.map((fieldStaff) => (
													<Grid
														item
														xs={4}
														key={fieldStaff.userId}>
														<FieldStaff
															Staff={fieldStaff}
															updateUi={updateUi}
														/>
													</Grid>
												))
										: ""}
								</Grid>
							</Grid>
						</Grid>
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default PSPList;
