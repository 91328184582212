import React, { useEffect } from "react";
import bootstrapFromAPI from "../../bootstrapData";
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { useState } from "react";
import { useRef } from "react";
import { IDLE_SESSION_MAX } from "../../config";

export default function VersionCheck() {
	const project = useSelector((state) => state.ProjectReducer.projectInfo);
	const [needsSync, setNeedsSync] = useState(false);
	const [activityTO, setActivityTO] = useState(new Date());
	const [, setMounted] = useState(false);
	const [chgCount, setChgCount] = useState(0);
	const chgRef = useRef(chgCount);
	chgRef.current = chgCount;

	const timeOutAlert = () => {
		setTimeout(() => {
			window.location.href = "/";
		}, 15000);
		confirmAlert({
			title: "Session Logout Warning",
			message:
				"You have been inactive for more than " +
				IDLE_SESSION_MAX / 60 +
				" minutes, you will be redirected to the homepage in 15 seconds if no action is taken.",
			buttons: [
				{
					label: "Continue Session",
					onClick: () => {
						setActivityTO(new Date());
					},
				},
				{
					label: "Exit to homepage",
					onClick: () => (window.location.href = "/"),
				},
			],
		});
	};

	const syncAlert = () => {
		confirmAlert({
			title: "Project saved by another user",
			message: "Another user has saved the same project. You will need to reload or exit out of the current project.",
			buttons: [
				{
					label: "Reload PSP",
					onClick: () =>
						bootstrapFromAPI.fetchProject(project.wbs1).then((a) => {
							var loc = window.location.href;
							window.location.href = loc;
						}),
				},
				{
					label: "Exit to homepage",
					onClick: () => (window.location.href = "/"),
				},
			],
		});
	};

	function checkProjectVersion() {
		try {
			var diff = (new Date().getTime() - activityTO.getTime()) / 1000;

			if (diff > IDLE_SESSION_MAX) {
				timeOutAlert();
			}

			if (!project.wbs1) return;

			bootstrapFromAPI.isProjectUpToDate(project).then((resp) => {
				if (resp) {
					var count = chgRef.current + 1;
					console.log("bootstrapFromAPI.isProjectUpToDate", count);

					setChgCount(count);
					if (count > 2) {
						syncAlert();
						setNeedsSync(true);
					}
				} else {
					setChgCount(0);
					setNeedsSync(false);
				}
			});
		} catch (ex) {
			console.log(ex);
		}
	}

	useEffect(() => {
		setMounted(true);
		let toHand = setInterval(checkProjectVersion, 5000);

		console.log("Version Checker enabled");
		console.log("Session timeout set to " + IDLE_SESSION_MAX + " seconds");
		return () => {
			clearInterval(toHand);

			setMounted(false);
		};
	}, [setMounted, activityTO]); // eslint-disable-line react-hooks/exhaustive-deps

	return needsSync ? (
		<>Project out of sync</>
	) : (
		//No Need
		<> </>
	);
}
