const initialState = {
	deltekProjectList: [],
	isProcessing: false,
	isError: false,
};

const DeltekProjectListReducer = (state = initialState, deltekAction) => {
	switch (deltekAction.type) {
		case "FETCH_DELTEK_PROJECTS": {
			return {
				deltekProjectList: [],
				isProcessing: false,
				isError: false,
			};
		}
		case "RECEIVED_DELTEK_PROJECTS": {
			return {
				deltekProjectList: deltekAction.payload,
				isProcessing: false,
				isError: false,
			};
		}

		case "FETCH_DELTEK_PROJECT_ERROR": {
			return {
				deltekProjectList: [],
				isProcessing: false,
				isError: true,
			};
		}

		default: {
			return state; /* {
                deltekProjectList:state.deltekProjectList,
                isProcessing:false,
                isError:false
            } */
		}
	}
};

export default DeltekProjectListReducer;
