export const HazardSummary = {
	title: "Hazard Summary Sample:",
	value: "",
	name: "hazardSum",
	label: "Site Hazard Review Summary",
	rows: 8,
	required: true,
	hint: "Using the check lists on the previous page for General Field Exposures, Construction Site Safety and Specialized Work Environments/Tasks.  To view an example Hazard Summary Sample, click anywhere in this box to open.  You can use this as a template to edit the paragraph describing the potential exposures and the avoidance measures identified to minimize them.",
	example:
		"The project involves field work that may result in the General Field Exposures identified below. Standard avoidance measures for each exposure are identified in the Guidance Documents, and include: COVID-19 Field Procedures GD1; Personal Protective Equipment PPE appropriate for the work assignment GD7; personal safety, including planning for working in pairs/alone GD2 and work in rural GD5 or urban locations GD6; safe driving GD3 including night or off-road driving GD11, GD12; safe loading/lifting techniques GD4; safety around small equipment, hand equipment and hazardous materials GD8, GD9, GD10; field exposures, including: heat and heat illness GD13, cold and hypothermia GD14, inclement weather, trip/fall and working on slopes GD15; exposure to confrontation GD16, illegal activities GD17, domestic animals GD19, or wildlife GD18; exposure to venomous snakes GD20, bats GD22 or alligators/crocodiles GD21; exposure to allergens or insects that may cause allergic reactions GD24 or carry infectious diseases, including: poison oak GD23, poisonous insects GD27, mosquitos GD26 and ticks GD25; exposure to nuisance dust that may necessitate the use of voluntary N95 respirators GD29, GD30, GD31; exposure to nuisance dust at that may include spores that can result in Valley Fever GD32; exposure to wildfire GD28 and exposure to wildfire smoke GD33.",
};
