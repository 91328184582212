export const site = {
	siteId: null,
	sow: "",
	siteDescription: "",
	hazardSum: "",
	otherContactInformation: "",
	no911: false,
	emergencyContactInfo: "",
	version: 0,
};
