import { makeStyles } from "@material-ui/core/styles";

//App Root Style
export const AppStyles = makeStyles({
	root: {
		margin: "auto",
		/*New sizing */
		minWidth: 830,
		maxWidth: 1750,
		/*end New sizing */
		backgroundColor: "#f1f1f1",
		padding: "1.25rem",

		minHeight: 1700,
	},
	rootbar: {
		margin: "left",
		/*New sizing */
		minWidth: 830,
		maxWidth: 1750,
		/*end New sizing */
		backgroundColor: "#f1f1f1",
		padding: "1.25rem",
	},
});
export const QueryStyles = makeStyles((theme) => ({
	root: {
		margin: "auto",
		marginTop: 20,
		height: "1600",
		/*New sizing */
		minWidth: 830,
		maxWidth: 1750,
		/*end New sizing */
		backgroundColor: "#f1f1f1",
		padding: 5,
		paddingLeft: 20,
		paddingRight: 20,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));
export const ResourcesStyles = makeStyles({
	root: {
		backgroundColor: "#FFFFFF",
		alignItems: "center",
		textAlign: "center",
	},
	paper: {
		padding: 1,
		borderRadius: 20,
	},
	leftAlign: {
		textAlign: "Left",
	},
	resources: {
		height: 150,
		display: "inline-grid",
		verticalAlign: "middle",
		fontSize: 30,
		color: "#666666",
		fontWeight: "bold",
		textAlign: "Left",
	},
	sectionHeader: {
		width: "100%",
		backgroundColor: "#E5F1F3",
		color: "#666666",
		textAlign: "Left",
		fontSize: 20,
		paddingTop: 5,
		paddingLeft: 10,
		paddingBottom: 5,
	},
	sectionSubHeader: {
		backgroundColor: "#FFFFFF",
		fontSize: 24,
		color: "#0FAA99",
		textAlign: "Left",
		paddingTop: 10,
	},
	documentLink: {
		color: "#5E9696",
		textDecoration: "none",
		textAlign: "left",
		paddingBottom: 5,
	},
});

export const TrainingStyles = makeStyles({
	root: {
		backgroundColor: "#FFFFFF",
		alignItems: "center",
		textAlign: "center",
	},
	paper: {
		padding: 1,
		borderRadius: 20,
	},
	leftAlign: {
		textAlign: "Left",
	},
	training: {
		height: 150,
		display: "inline-grid",
		verticalAlign: "middle",
		fontSize: 30,
		color: "#666666",
		fontWeight: "bold",
		textAlign: "Left",
	},
	sectionHeader: {
		backgroundColor: "#FFFFFF",
		fontSize: 24,
		color: "#666666",
		textAlign: "Left",
		marginTop: -50,
	},
	sectionSubHeader: {
		textAlign: "Left",
		backgroundColor: "#FFFFFF",
		fontSize: 20,
		color: "#666666",
		paddingBottom: 20,
	},
	documentLink: {
		color: "#5E9696",
		textDecoration: "none",
		textAlign: "left",
		paddingBottom: 5,
	},
	clickSafetyText: {
		textAlign: "Left",
		lineHeight: 2,
	},
	curriculum: {
		width: "100%",
		backgroundColor: "#E5F1F3",
		color: "#666666",
		textAlign: "Left",
		fontSize: 20,
		paddingTop: 5,
		paddingLeft: 10,
		paddingBottom: 5,
	},
	course: {
		backgroundColor: "#FFFFFF",
		fontSize: 24,
		color: "#0FAA99",
		textAlign: "Left",
		paddingTop: 10,
	},

	courseDescription: {
		backgroundColor: "#FFFFFF",
		textAlign: "Left",
		paddingTop: 10,
		paddingBottom: 20,
	},
});

export const HomeStyles = makeStyles({
	root: {
		maxWidth: "100",
	},
	h5: {
		margin: "auto",
	},
	paper: {
		padding: 1,
		borderRadius: 20,
	},
	topLeft: {
		backgroundColor: "#3D3D3D",
		borderRadius: 20,
		margin: "auto",
		color: "#FFFFFF",
	},
	topRight: {
		height: 340,
		backgroundColor: "#004361",
		width: 545,
		borderRadius: 20,
		margin: "auto",
		color: "#FFFFFF",
	},
	centerIcon: {
		margin: "auto",
	},
	iconText: {
		margin: "auto",
		textAlign: "center",
		fontWeight: "bolder",
		fontSize: 24,
	},
	iconSubText: {
		marginTop: -10,
		padding: 10,
		color: "#000000",
		fontSize: 14,
	},
	newPSP: {
		margin: "auto",
		borderRadius: 20,
		color: "#007C90",
		//backgroundImage: 'linear-gradient(#71CAD1, #004361)',
	},
	report: {
		margin: "auto",
		borderRadius: 20,
		color: "#007C90",
		//backgroundImage: 'linear-gradient(#71CAD1, #00A69C)',
	},
	resources: {
		margin: "auto",
		borderRadius: 20,
		color: "#007C90",
		//backgroundImage: 'linear-gradient(#80C99B,#007C90)',
	},
	quiz: {
		margin: "auto",
		borderRadius: 20,
		color: "#007C90",
		//backgroundImage: 'linear-gradient(#ADD376,#00A69C)',
	},
	heroIcon: {
		marginLeft: -40,
		marginTop: -15,
		borderRadius: 20,
		height: 300,
		width: "100%",
	},
	homeLink: {
		color: "#FFFFFF",
		textDecoration: "none",
	},
});

export const StepOneStyles = makeStyles({
	root: {
		backgroundColor: "#f1f1f1",
		height: "100%",
	},
	paper: {
		padding: 1,
		borderRadius: 10,
	},
	card: {
		backgroundColor: "#FFFFFF",
	},
	cardHeader: {
		backgroundColor: "#004361",
		fontSize: 12,
		color: "#FFFFFF",
	},
	activityName: {
		fontSize: 10,
	},
	activityDescription: {
		paddingLeft: 30,
		fontSize: 14,
	},
	buttonRight: {
		textAlign: "right",
		alignItems: "right",
	},
	projectName: {
		backgroundColor: "#E5F1F3",
		paddingLeft: 5,
		border: 0,
	},
});

export const StepTwoStyles = makeStyles({
	root: {
		backgroundColor: "#f1f1f1",
		height: "100%",
	},
	paper: {
		padding: 1,
		borderRadius: 10,
	},
	card: {
		backgroundColor: "#FFFFFF",
	},
	cardHeader: {
		backgroundColor: "#004361",
		fontSize: 12,
		color: "#FFFFFF",
	},
	buttonRight: {
		textAlign: "right",
		alignItems: "right",
	},
	dialogTitle: {
		display: "flex",
		backgroundColor: "red",
		color: "#FFF",
	},
	dialogTitlePrimary: {
		display: "flex",
		backgroundColor: "#17A2B8",
		color: "#FFF",
	},
});

export const StepThreeStyles = makeStyles({
	root: {
		backgroundColor: "#f1f1f1",
		height: "100%",
	},
	paper: {
		padding: 1,
		borderRadius: 10,
	},
	card: {
		backgroundColor: "#FFFFFF",
	},
	cardHeader: {
		backgroundColor: "#004361",
		fontSize: 12,
		color: "#FFFFFF",
	},
	fullWidthTextArea: {
		width: "98%",
		padding: 10,
		fontFamily: "arial",
		fontSize: 14,
		borderColor: "#F1F1F1",
		borderRadius: 5,
	},
	buttonRight: {
		textAlign: "right",
		alignItems: "right",
	},
	buttonAlignBottom: {
		marginTop: 15,
	},
	staffFieldContainer: {
		minHeight: 170,
		maxHeight: 170,
		overflow: "auto",
	},
});

export const StepFourStyles = makeStyles({
	root: {
		backgroundColor: "#f1f1f1",
		height: "100%",
	},
	paper: {
		padding: 1,
		borderRadius: 10,
	},
	card: {
		backgroundColor: "#FFFFFF",
	},
	cardHeader: {
		backgroundColor: "#004361",
		fontSize: 12,
		color: "#FFFFFF",
	},
	fullWidthTextArea: {
		width: "98%",
		padding: 10,
		fontFamily: "arial",
		fontSize: 14,
		borderColor: "#F1F1F1",
		borderRadius: 5,
	},
	buttonRight: {
		textAlign: "right",
		alignItems: "right",
	},
	buttonFull: {
		width: "100%",
	},
	dialogTitle: {
		display: "flex",
		backgroundColor: "#FFC107",
	},
});

export const StepFiveStyles = makeStyles({
	root: {
		backgroundColor: "#f1f1f1",
		height: "100%",
	},
	paper: {
		padding: 1,
		borderRadius: 10,
	},
	card: {
		backgroundColor: "#FFFFFF",
	},
	cardHeader: {
		backgroundColor: "#004361",
		fontSize: 12,
		color: "#FFFFFF",
	},
	fullWidthTextArea: {
		width: "98%",
		padding: 10,
		fontFamily: "arial",
		fontSize: 14,
		borderColor: "#F1F1F1",
		borderRadius: 5,
	},
	buttonRight: {
		textAlign: "right",
		alignItems: "right",
	},
	buttonFull: {
		width: "100%",
	},
});

//This is the style for the reuable project bar component
export const ProjectInfoStyles = makeStyles({
	infoBar: {
		width: "100%",
		padding: 10,
		backgroundColor: "#E5F1F3",
	},
});

export const RequiredFieldStyles = makeStyles({
	requiredField: {
		padding: 10,
		fontFamily: "arial",
		color: "red",
	},
});

export const MyPSPStyles = makeStyles({
	root: {
		backgroundColor: "#f1f1f1",
		minHeight: "95vh",
	},
	paper: {
		padding: 1,
		borderRadius: 10,
	},
	card: {
		backgroundColor: "#FFFFFF",
	},
	cardHeader: {
		textAlign: "left",
		backgroundColor: "#004361",
		fontSize: 12,
		color: "#FFFFFF",
	},
});

//This is the reuable component that is in step 2
export const TextAreaWithHintStyles = makeStyles({
	dialogTitle: {
		display: "flex",
		backgroundColor: "#F1F1F1",
	},
	paper: {
		backgroundColor: "#F1F1F1",
		border: "2px solid #000",
		padding: 5,
	},
	fullWidthTextArea: {
		width: "100%",
		padding: 10,
		fontFamily: "arial",
		fontSize: 14,
		borderColor: "#F1F1F1",
		borderRadius: 5,
		lineHeight: "1.5",
	},
	formLabelGreen: {
		paddingBottom: 15,
		color: "green",
	},
	formLabelRed: {
		paddingBottom: 15,
		color: "red",
	},
	fieldHint: {
		width: "95%",
		padding: 10,
		fontFamily: "arial",
		fontSize: 14,
		backgroundColor: "#E5F1F3",
		borderRadius: 5,
		lineHeight: "1.5",
	},
});

//This is the reuable component that is in step 2
export const PSPListStyles = makeStyles({
	dialogTitle: {
		display: "flex",
		backgroundColor: "#F1F1F1",
	},
	paper: {
		backgroundColor: "#F1F1F1",
		border: "2px solid #000",
		padding: 5,
	},
});

export const AcknowledgementStyles = makeStyles({
	root: {
		backgroundColor: "#f1f1f1",
		minHeight: "95vh",
	},
	title: {
		color: "#666666",
		paddingLeft: 20,
	},
	projectName: {
		color: "#000000",
		backgroundColor: "#FFFFFF",
	},
	paper: {
		padding: 1,
		borderRadius: 10,
	},
	leftBox: {
		paddingTop: 50,
		paddingBottom: 50,
		paddingLeft: 50,
	},
	rightBox: {
		paddingTop: 50,
		paddingBottom: 50,
		margin: "auto",
		textAlign: "center",
		borderRadius: 30,
		backgroundColor: "#F1F1F1",
	},
	boxContainer: {
		margin: "auto",
		paddingTop: 50,
		paddingBottom: 50,
		width: 250,
		height: 250,
	},
	card: {
		backgroundColor: "#FFFFFF",
	},
	cardHeader: {
		backgroundColor: "#007C90",
		fontSize: 12,
		color: "#FFFFFF",
	},
	cardHeaderWhite: {
		backgroundColor: "#FFF",
		fontSize: 12,
		color: "#000",
	},
});

const drawerWidth = 410;
export const ticketStyles = makeStyles((theme) => ({
	reviewContainer: {
		width: "400",
		paddingLeft: 10,
	},

	approveContainer: {
		backgroundColor: "#BFDDDA",
		paddingLeft: 30,
		display: "inline",
	},

	pageCommentContainer: {
		width: "300",
		paddingLeft: 20,
		textAlign: "left",
	},

	ticketContainer: {},
	appBar: {
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	drawerOpen: {
		width: drawerWidth,
		padding: 5,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(6) + 1,
		},
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	hide: {
		display: "none",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerHeader: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: "flex-end",
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: -drawerWidth,
	},
	contentShift: {
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
	ticket: {
		alignSelf: "center",
	},
	ticketDate: {
		fontSize: 11,
	},
	TicketContainer: {
		minHeight: 100,
		textAlign: "left",
	},
	ticketUser: {
		color: "#666666",
		fontWeight: 550,
		fontSize: 20,
	},
	ticketTitleBar: {
		backgroundColor: "#BFDDDA",
		width: "100%",
		ticketIcon: {
			backgroundColor: "#00A69C",
			color: "#ffffff",
		},
	},
	ticketStatusText: {
		color: "#ffffff",
		fontWeight: 600,
		fontSize: 18,
	},
	ticketStatuspending: {
		backgroundColor: "#004361",
		color: "#ffffff",
		alignItems: "center",
	},
	ticketStatusclosed: {
		backgroundColor: "#666666",
		color: "#ffffff",
		alignItems: "center",
	},
	ticketStatusopen: {
		backgroundColor: "#00A69C",
		color: "#ffffff",
		alignItems: "center",
	},
	ticketStatustotal: {
		backgroundColor: "#00A69C",
		color: "#ffffff",
		alignItems: "center",
	},
	ticketCommentText: {
		color: "#66666A",
		background: "#E5F1F3",
	},
	ticketControl: {
		color: "#00A69C",
		backgroundColor: "transparent",
		fontSize: 32,
	},
	ticketIcon: {
		backgroundColor: "#00A69C",
		color: "#ffffff",
	},
	selectedScope: {
		borderRadius: 5,
		padding: 5,
		borderStyle: "dotted",
	},
	ticketBar: { backgroundColor: "transparent", overflow: "visbile" },
	reviewDisplayHeader: {
		color: "#00A69C",
		backgroundColor: "transparent",
		fontSize: 16,
	},
	reviewDisplayHeader2: {
		color: "#00A69C",
		backgroundColor: "transparent",
		fontSize: 22,
		textAlign: "center",
	},
	ticketBarGrid: {},
	ticketCounter: {
		backgroundColor: "#BFDDDA",
	},
	ticketBarTitle: {
		background: "#00A69C",
		fontSize: 30,
		color: "#ffffff",
		borderTopLeftRadius: 20,
		borderTopRightRadius: 20,
		alignItems: "center",
		alignContent: "center",
		textAlign: "center",
	},

	childTicketDate: {
		paddingLeft: 10,
		fontSize: 12,
		fontWeight: 600,
	},
	childTicketUser: {
		paddingLeft: 10,
		fontSize: 14,
		fontWeight: 600,
	},
	childTicketComment: {
		paddingLeft: 10,
		paddingBottom: 15,
		fontSize: 14,
	},
	childTicket: {
		background: "#ffffff",
	},
}));
