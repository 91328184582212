export const selects = (options, activityLevels, guidanceDocs) => {
	//Reformat activity Levels
	const getActivityLevels = () => {
		var ret = [];
		activityLevels.forEach((a) => {
			ret.push({ label: a.activityLevelName, value: a.activityLevelId });
		});
		return ret;
	};

	const listToOptions = (list) => {
		//assumes simple array
		let ret = [];
		list.forEach((a) => {
			ret.push({ label: a, value: a });
		});
		return ret;
	};

	return [
		{
			id: "statuses",
			label: "Status",
			options: [
				{ label: "In Progress", value: 1 },
				{ label: "Submitted", value: 2 },
				{ label: "Approved", value: 3 },
				{ label: "No Fieldwork", value: 4 },
			],
		},
		{
			id: "regions",
			label: "Region",
			options: [
				{ label: "South East", value: "SE" },
				{ label: "North West", value: "NW" },
				{ label: "South West", value: "SW" },
				{ label: "Northern California", value: "NC" },
				{ label: "Corperate", value: "CO" },
				{ label: "Southern California", value: "SC" },
			],
		},
		{ id: "activityLevels", label: "Activity Level", options: getActivityLevels() },
		{ id: "businessGroups", label: "Business Group", options: listToOptions(options.businessGroups) },
		{ id: "projectUsers", label: "People", options: listToOptions(options.projectUsers) },
		{ id: "guidanceDocs", label: "Guidance Docs", options: listToOptions(options.guidanceDocs) },
	];
};
