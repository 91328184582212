const fetch_ProjectList = () => {
	return {
		type: "FETCH_DELTEK_PROJECTS",
	};
};

const received_ProjectList = (projectList) => {
	return {
		type: "RECEIVED_DELTEK_PROJECTS",
		payload: projectList,
	};
};

const fetch_ProjectList_Error = () => {
	return {
		type: "FETCH_DELTEK_PROJECTS_ERROR",
	};
};

export default {
	fetch_ProjectList,
	received_ProjectList,
	fetch_ProjectList_Error,
};
