import React from "react";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { ProjectInfoStyles } from "../../../Styles";

const ProjectInfoBar = (props) => {
	const classes = ProjectInfoStyles();
	const projectInfo = props.projectInfo;

	return (
		<Card>
			<Typography
				variant="body1"
				className={classes.infoBar}>
				{projectInfo?.projectNumber?.length === 0 ? "New Project Safety Plan" : (projectInfo?.projectNumber + " : " + projectInfo.projectName)}
			</Typography>
		</Card>
	);
};

export default ProjectInfoBar;
