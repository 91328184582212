const fetch_UserList = () => {
	return {
		type: "FETCH_DELTEK_USERS",
	};
};

const received_UserList = (userList) => {
	return {
		type: "RECEIVED_DELTEK_USERS",
		payload: userList,
	};
};

const fetch_UserList_Error = () => {
	return {
		type: "FETCH_DELTEK_USERS_ERROR",
	};
};

export default {
	fetch_UserList,
	received_UserList,
	fetch_UserList_Error,
};
