const fetch_ExistingWBS1s = () => {
	return {
		type: "FETCH_EXISTINGWBS1S",
	};
};

const received_ExistingWBS1s = (wbs1s) => {
	return {
		type: "RECEIVED_EXISTINGWBS1S",
		payload: wbs1s,
	};
};

const fetch_ExistingWBS1s_Error = () => {
	return {
		type: "FETCH_EXISTINGWBS1S_ERROR",
	};
};

export default {
	fetch_ExistingWBS1s,
	received_ExistingWBS1s,
	fetch_ExistingWBS1s_Error,
};
