/* 
        Project             : eSafety 2.0
        Client              : Internal
        Author Company      : ESA
        Author              : Peter Ross
        Contact             : pross@esassoc.com

*/
export const IDLE_SESSION_MAX = 18000; //How many seconds before a user is logged out.
export const config = {
  appId: "27db8eb1-7175-4691-9a4d-6b05e7450aad",
  redirectUri: process.env.NODE_ENV === "production" ? window.location.origin : window.location.origin,
  scopes: ["user.read", "user.readbasic.all"],
};

export const AUTHREDIR = () => {
  return process.env.NODE_ENV === "production" ? window.location.origin : "http://localhost:3000";
};

export const IsDevCopy = () => {
	if (window.location.href.indexOf("esasafety.esassoc.com") > -1) return false;
	return true;
};

export const API_URI_BASE = () => {
  //DO NOT CHANGE THIS THEN CHECK IT IN. GIT VERSION SHOULD ONLY POINT LOCALLY OR TESTING API.
  let uri = "http://127.0.0.1:8081/api";
  uri = "https://esafety-express-api.azurewebsites.net/api";

  let location = window.location.href;

  if (location.indexOf("localhost") > -1) {
    return uri = "http://localhost:8081/api";
  }

  let deploymentSlot = location.split(".")[0].split("-")[3];
  if (deploymentSlot) {
    if (location.indexOf(`https://e-safety-ui-${deploymentSlot}.azurewebsites.net`) > -1) {
      return `https://esafety-express-api-${deploymentSlot}.azurewebsites.net/api`;
    }
  }

  if (process.env.NODE_ENV === "production") {
    uri = "https://esafety-express-api.azurewebsites.net/api";
  }

  if (location.indexOf("esasafety-stage2.esassoc.com") > -1) {
    uri = "https://esafety-express-api.azurewebsites.net/api";
  }

  /*        P R O D U C T I O N   O N L Y       **/
  if (location.indexOf("esasafety.esassoc.com") > -1) {
    uri = "https://esafety-express-api.azurewebsites.net/api";
  }

  return uri;
};
export const MUI_GRID_PRO_LIC = "";
export const DELTEK_API_URI_BASE = () => {
	var uri = "https://vision.esassoc.com/internal/api/";

	return uri;
};

export const IS_PRODUCTION = window.location.href.indexOf("esasafety.esassoc.com") > -1;

export const GOOGLE_API_KEY = "AIzaSyBp6DYgxo1G7CJvpVeHtBvysS897gdLbMg";
