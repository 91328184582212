export const Project = {
	businessGroup: "",
	nfwsignatureId: null,
	nfwsignature: null,
	projectName: null,
	projectActivityLevels: [],
	projectContacts: [],
	projectEndDate: null,
	projectFacilities: [],
	projectGuidanceDocuments: [],
	projectid: null,
	projectStartDate: null,
	projectUsers: [],
	site: {},
	siteId: null,
	version: 1,
	wbs1: "",
};
