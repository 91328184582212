export const deltekProject = {
	wbs1: "",
	projectName: "",
	projectManager: "",
	email: "",
	organization: "",
	organizationName: "",
	StartDate: "",
	EndDate: "",
};
