const fetch_Authen = () => {
	return {
		type: "FETCH_Authen",
	};
};

const received_Authen = (Authen) => {
	return {
		type: "RECEIVED_Authen",
		payload: Authen,
	};
};

const fetch_Authen_Error = () => {
	return {
		type: "FETCH_Authen_ERROR",
	};
};

export default {
	fetch_Authen,
	received_Authen,
	fetch_Authen_Error,
};
