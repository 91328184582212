const initialState = {
	documentSet: [],
	isProcessing: false,
	isError: false,
};

const DocumentReducer = (state = initialState, documentAction) => {
	switch (documentAction.type) {
		//Set Spinner
		case "FETCH_DOCUMENTS": {
			return {
				documentSet: [],
				isProcessing: false,
				isError: false,
			};
		}
		case "RECEIVED_DOCUMENTS": {
			return {
				documentSet: documentAction.payload,
				isProcessing: false,
				isError: false,
			};
		}
		case "FETCH_DOCUMENTS_ERROR": {
			return {
				documentSet: [],
				isProcessing: false,
				isError: true,
			};
		}

		default: {
			return state; /* {
                documentSet:state.documentSet,
                isProcessing:false,
                isError:false
            } */
		}
	}
};

export default DocumentReducer;
